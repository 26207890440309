import axios from 'axios'
import { StateStore } from "@/store/StateStore";

// SB: https://github.com/vuejs/pinia/discussions/664#discussioncomment-1329898
function getStoreWrapper() {
    let store;
    return (() => {
        if (!store)
            store = StateStore()
        return store
    });
}

const getStore = getStoreWrapper()

class HanaWeb {
    constructor(){
        this.web = axios.create({
            // baseURL: getStore().baseURL,
            // timeout: 1000,
            //headers: {'X-Custom-Header': 'foobar'}
        })
        this.web.interceptors.request.use((config) => {
            const store = getStore()
            if (!config.baseURL) {
                config.baseURL = getStore().baseURL
            }
            config.headers.set('Authorization', store.accessToken)
            return config
        })

        this.web.interceptors.response.use((e)=>e, (resp)=>{
            if (resp.response && (resp.response.status >= 400 && resp.response.status < 500) && this.authCallback) {
                this.authCallback(resp)
                return
            }
            return Promise.reject(resp)
        })
    }

    async bookList(page, pageSize, order) {
        const url = `/book/list/${page}/${pageSize}/${order}`
        const resp = await this.web.get(url)
        return resp.data
    }
    
    bookCoverURL(bookID) {
        return getStore().baseURL + `/book/cover/${bookID}`
    }
    
    // async bookData(bookID, format) {
    //     return this.downloadBin(`${getStore().baseURL}/book/${bookID}/${format}`)
    // }

    bookDataURL(bookID, format) {
        return `${getStore().baseURL}/book/${bookID}/${format}`
    }

    // downloadBin(url, responseType = 'arraybuffer', loadingFunc = null) {
    //     return axios.get(url, {
    //         responseType: responseType,
    //         onDownloadProgress: loadingFunc
    //     }).then(resp => {
    //         let f = new File([resp.data], '')
    //         console.log(`${url} dl fin, size ${f.size}`)
    //         return f
    //     })
    // }

    async shelfList(bookID = 0, tagShelf = true) {
        return (await this.web.get(`/shelf/list/${bookID}`, {params: { tagshelf: tagShelf }})).data
    }
    async addToShelf(shelfID, bookID) {
        return await this.web.post(`/shelf/${shelfID}/book/${bookID}`)
    }
    async removeFromShelf(shelfID, bookID) {
        return await this.web.delete(`/shelf/${shelfID}/book/${bookID}`)
    }
    async shelf(shelfID, page, pagesize, order) {
        return (await this.web.get(`/shelf/${shelfID}/${page}/${pagesize}/${order}`)).data
    }

    async search(query, page, pagesize, order) {
        const resp = (await this.web.get(`/search/${page}/${pagesize}/${order}`, {
            params: { query }
        }))
        return resp.data
    }

    async login(username, password) {
        return this.web.post(`/login`, {username, password})
    }

    async me() {
        return (await this.web.get(`/me`)).data
    }

    async getTags() {
        return (await this.web.get(`/tags`)).data
    }

    async bookDetail(bookID = []) {
        return (await this.web.post(`/book/detail`, {bookID})).data
    }

    setAuthCallback(callback) {
        this.authCallback = callback
    }
}

class EhWeb {
    constructor() {
        this.web = axios.create({
            withCredentials: false
        })
        this.web.interceptors.request.use((config) => {
            if (!config.baseURL) {
                config.baseURL = getStore().baseEhURL
            }
            if (getStore().exCookies) {
                config.headers.set('ex-ctx', getStore().exCookies)
                config.withCredentials = true
            }
            return config
        })
    }

    async search(pattern, nextGid=null, isNext=true) {
        const params = { pattern }
        if (nextGid) {
            if (isNext)
                params['next'] = nextGid
            else
                params['prev'] = nextGid
        }
        return (await this.web.get(`/search`, {params})).data.works
    }

    async gallery(gid, gtoken, page=0) {
        return (await this.web.get(`/gallery`, {params: {gid, gtoken, page}})).data
    }

    imageURL(gid, gtoken, itoken, ptoken) {
        return `${getStore().baseEhURL}/image?gid=${gid}&gtoken=${gtoken}&itoken=${itoken}&ptoken=${ptoken}`
    }

    async addToShelf(gid, gtoken, favID) {
        return await this.web.post(`/favorite?gid=${gid}&gtoken=${gtoken}&favID=${favID}`)
    }
    async removeFromShelf(gid, gtoken) {
        return await this.web.delete(`/favorite?gid=${gid}&gtoken=${gtoken}`)
    }
    async shelfList(gid, gtoken) {
        return (await this.web.get(`/favorite?gid=${gid}&gtoken=${gtoken}`)).data
    }

    async getConfig() {
        return (await this.web.get(`/uconfig`)).data
    }

    async modifyConfig(value) {
        return (await this.web.post(`/uconfig?profile=${value}`)).data
    }
}

const hanaWeb = new HanaWeb()
const ehWeb = new EhWeb()

const install = function(Vue) {
    Vue.prototype.$hanaWeb = hanaWeb
    Vue.prototype.$ehWeb = ehWeb
}

const hana = {
    install
}

export {hana, hanaWeb, ehWeb}
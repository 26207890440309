import {hanaWeb} from '@/components/web'

class AbortError {}

class dlContext {
    constructor(URL, downloader, web = hanaWeb.web) {
        this.URL = URL
        this.abortCtrl = new AbortController()
        this.aborted = false
        this.downloader = downloader
        this.progress = {
            total: 0,
            loaded: 0,
        }
        this.error = null
        this.promise = web.get(URL, {
            responseType: "arraybuffer",
            signal: this.abortCtrl.signal,
            onDownloadProgress: (ev)=>{
                this.progress.total = ev.total
                downloader.lastCountBytes += ev.loaded - this.progress.loaded
                this.progress.loaded = ev.loaded
            },
        }).then((resp) => {
            if (this.aborted)
                throw AbortError
            let f = new File([resp.data], '')
            console.log(`${URL} dl fin, size ${f.size}`)
            return f
        }).catch((error) => {
            this.error = error
            if (error instanceof AbortError)
                return
            throw error
        }).finally(()=>{
            this.downloader.queue.delete(URL)
        })
    }

    abort() {
        if (this.aborted)
            return
        this.abortCtrl.abort()
        this.aborted = true
    }

    retry() {
        this.abort()
        this.downloader.download(this.URL, true, this.web)
        return this.downloader.queue.get(this.URL)
    }
}

class Downloader {
    constructor() {
        this.queue = new Map()
    }

    context(URL) {
        return this.queue.get(URL)
    }

    download(URL, force = false, web = hanaWeb.web) {
        if (this.queue.has(URL)) {
            const entry = this.queue.get(URL)
            if (!force)
                return entry
            entry.abort()
            this.queue.delete(URL)
        }
        const entry = new dlContext(URL, this, web)
        this.queue.set(URL, entry)
        return entry
    }

    abort(URL) {
        if (!this.queue.has(URL))
            return
        const ctx = this.queue.get(URL)
        ctx.abort()
        this.queue.delete(URL)
    }

    isDownloading(URL) {
        return this.queue.has(URL)
    }

    async sleep(ms) {
        return new Promise((resolve) => {
            return setTimeout(() => {
                resolve()
            }, ms)
        })
    }

    setNotifycallback(callback) {
        this.notifyCallback = callback;
        (async ()=>{
            for (;;) {
                this.lastCountBytes = 0
                await this.sleep(1000)

                let loaded = 0
                let total = 0
                for (const task of this.queue.values()) {
                    loaded += task.progress.loaded
                    total += task.progress.total
                }

                this.notifyCallback(this.queue.size, this.lastCountBytes, (total == 0 ? 100 : (loaded / total) * 100))
            }
        })()
    }
}

const install = function(Vue) {
    const web = new Downloader()
    Vue.prototype.$downloader = web
}

export default {install}
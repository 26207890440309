import { BlobEntry } from '@/components/LruBlob'
import { ehWeb } from '@/components/web.js'
import storage from '@/pages/Reader/CachedStorage'
import ehData from "@/pages/ehViewer/ehData.js"
import { StateStore } from "@/store/StateStore";

// todo: use Base class to connect ChunkManager and ehPageManager
class ehPageManager {
    // bookDetail must be first page
    constructor(bookDetail, lruBlob, downloader, reader) {
      console.log(bookDetail.imgHref)
      this.galleryPageTotal = bookDetail.thumb.totalPage
      if (!bookDetail.imgHref.has('0')) {
        throw Error("detail imgHref does't have page zero")
      }
      this.galleryPageSize = bookDetail.imgHref.get('0').length
      this.imgHref = bookDetail.imgHref
      this.pages = []
      for (let i = 0; i < bookDetail.length; ++i) {
        this.pages.push({
          name: "image-" + i, //useless
          url: "",
          index: i,
          ctx: null,
        });
      }
      this.lru = lruBlob
      this.downloader = downloader
      this.reader = reader
      this.gid = bookDetail.gid
      this.gtoken = bookDetail.gtoken
      this.detail = bookDetail
      this.storage = storage()
    }

    toJson() {
      const data = this.detail
      data['imgHref'] = Object.fromEntries(this.imgHref)
      return data
    }

    static fromJson(data, lruBlob, downloader, reader) {
      if (!(data.imgHref instanceof Map)) {
        data.imgHref = new Map(Object.entries(data.imgHref))
      }
      return new ehPageManager(data, lruBlob, downloader, reader)
    }

    galleryIndex(pageIndex) {
      return String(parseInt(pageIndex / this.galleryPageSize))
    }

    genLruKey(pageIndex) {
      return `${this.gid}-${pageIndex}`
    }

    parseLruKey(key) {
      return key.split('-')
    }
  
    pageLoaded(pageIndex) {
      const gIndex = this.galleryIndex(pageIndex)
      if (!this.imgHref.has(gIndex)) {
        return false
      }
      return this.lru.has(this.genLruKey(pageIndex))
    }

    async getImgHref(pageIndex) {
      const gIndex = this.galleryIndex(pageIndex)
      const pageOffset = pageIndex % this.galleryPageSize
      if (!this.imgHref.has(gIndex)) {
        const imgHref = await ehData.getImgHref(this.gid, this.gtoken, gIndex)
        if (imgHref?.length > 0) {
          console.log('set gallery page', gIndex, 'imgHref length', imgHref.length)
          this.imgHref.set(gIndex, imgHref)
        } else {
          console.error('load next gallery failed', this.gid, this.gtoken, pageIndex, imgHref)
          return //todo: retrieve error logic
        }
      }
      return this.imgHref.get(gIndex)[pageOffset]
    }

    evictEntry(key) {
      const [,pageIndex] = this.parseLruKey(key)
      this.pages[pageIndex].url = ''
    }

    async sleep(ms) {
      return new Promise((res) => {
        setTimeout(res, ms)
      })
    }

    genCachedKey(itoken) {
      return `ehHref-${itoken}`
    }

    async downloadPage(gid, gtoken, itoken, ptoken, pageIndex) {
      const imgURL = ehWeb.imageURL(gid, gtoken, itoken, ptoken)
      if (this.downloader.isDownloading(imgURL)) {
        return
      }
      const ctx = this.downloader.download(imgURL, false, ehWeb.web)
      this.pages[pageIndex].ctx = ctx
      return ctx.promise
    }
  
    async loadPage(pageIndex) {
      if (this.pageLoaded(pageIndex)) {
        return
      }
      const imgHref = await this.getImgHref(pageIndex)
      if (!imgHref) {
        return
      }
      const cachedKey = this.genCachedKey(imgHref[0])
      const file = await this.storage.getImageOrLoad(cachedKey, async () => this.downloadPage(this.gid, this.gtoken, imgHref[0], imgHref[1], pageIndex))
      if (!file) {
        return
      }
      const key = this.genLruKey(pageIndex)
      const entry = new BlobEntry([file], key, (entry) => this.evictEntry(entry.id))
      this.lru.set(key, entry)
      this.pages[pageIndex].url = entry.url[0]
    }

    async pageChange(currentPage) {
      const preloadPageSize = StateStore().preloadPages;
      const upper = Math.min(currentPage + preloadPageSize, this.pages.length);
      let halfLoaded = true
      for (let i = currentPage; i < Math.min(upper, currentPage + parseInt(preloadPageSize / 2)); ++i) {
        if (!this.pageLoaded(i)) {
          halfLoaded = false
          break
        }
      }
      if (!halfLoaded) {
        this.reader.$root.$sysBar.information(
          `load page ${currentPage + 1}-${upper}`
        );
        let count = 0;
        for (let i = currentPage; i < upper; ++i) {
          if (!this.pageLoaded(i)) {
            ++count
            this.loadPage(i);
            const sleepMs = Math.min(1000, 100 * (1 << (count + 1)))
            console.log('sleep', sleepMs)
            await this.sleep(sleepMs)
          }
        }
        return
      }
    }

    destroy() {
      for (let i = 0; i < this.pages.length; ++i) {
        this.lru.delete(this.genLruKey(i + 1))
      }
    }
}

export default ehPageManager
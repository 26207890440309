<template>
  <div>
    <div class="text-h3 py-4"></div>
    <book-view :books="books">
      <template v-slot:button-group>
        <v-btn value="rand" @click="getRandBooks()">
          <span>random</span>
          <v-icon small>
            mdi-shuffle
          </v-icon>
        </v-btn>
      </template>
    </book-view>
  </div>
</template>

<script>
import BookView from "@/components/BookView";

export default {
  name: "RandomBook",
  components: {
    BookView,
  },
  data: () => ({
    books: [],
    pageSize: 30,
  }),
  props: [],
  methods: {
    async getRandBooks() {
      const data = await this.setLoading(this.$hanaWeb.bookList(1, this.pageSize, "rand"));
      this.books = data["item"];
    },
    async setLoading(promise) {
      this.$root?.$sysBar?.setLoading(true)
      try {
        return await promise;
      } finally {
        this.$root?.$sysBar?.setLoading(false)
      }
    },
  },
  created() {
    this.getRandBooks();
  },
};
</script>
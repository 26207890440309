<template>
  <v-dialog @input="onInput" :value="value" :fullscreen="$vuetify.breakpoint.mobile"
    :max-width="!$vuetify.breakpoint.mobile ? '1500' : undefined">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon @click="onInput(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="title">Book Detail</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items style="width: 110px;" class="pt-2">
          <v-select v-model="selectedFormat" :items="selectableFormat" item-value="format" item-text="format" single-line
            filled :disabled="selectableFormat.length < 2" dense></v-select>
        </v-toolbar-items>
      </v-toolbar>
      <template v-if="this.currentBook">
        <div>
          <v-row class="px-5 pt-5">
            <v-col cols="3" class="d-flex align-center">
              <v-img :src="currentBook.cover || $hanaWeb.bookCoverURL(currentBook.id)" position="top" min-width="100"
                max-height="350" contain>
              </v-img>
            </v-col>
            <v-col cols="1"> </v-col>
            <v-col class="d-flex align-start flex-column">
              <v-card-title class="text-xs-h6 text-sm-h5 text-md-h4 mb-6">
                {{ currentBook.title }}
              </v-card-title>
              <v-card-text class="text-subtitle-1 text-truncate">
                <v-row>
                  <v-col class="col-lg-2 pb-1">publish:</v-col>
                  <v-col class="pb-1">{{ currentBook.pubdate }}</v-col>
                </v-row>
                <v-row v-if="tagGroup['uploader'] && tagGroup['uploader'].length > 0">
                  <v-col class="col-lg-2 px-3 pb-1 pt-0">uploader:</v-col>
                  <v-col class="py-0">{{ tagGroup['uploader'][0].name }}</v-col>
                </v-row>
                <v-row v-if="currentBook.favorite">
                  <v-col class="col-lg-2 px-3 pb-1 pt-0">favorited:</v-col>
                  <v-col class="py-0">{{ currentBook.favorite }}</v-col>
                </v-row>
                <v-row v-if="currentBook.authors.length > 0">
                  <v-col class="col-lg-2 px-3 pb-1 pt-0">artist:</v-col>
                  <v-col class="py-0">
                    <v-breadcrumbs :items="currentBook.authors" class="pa-0">
                      <template v-slot:item="{ item }">
                        <v-breadcrumbs-item>
                          {{ item.name }}
                        </v-breadcrumbs-item>
                      </template>
                    </v-breadcrumbs>
                    <!-- {{ tagGroup.get('artist')[0].name }} -->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>

          <v-row class="ma-2" justify="center">
            <div class="pa-0">
              <v-btn large plain rounded @click="loadShelfList"><v-icon dark>mdi-book-plus</v-icon>
              </v-btn>
            </div>
            <div class="pa-0">
              <v-btn large plain rounded><v-icon dark>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <div class="pa-0">
              <v-btn large plain rounded><v-icon dark>mdi-alpha-e-box-outline</v-icon>
              </v-btn>
            </div>
            <div class="pa-0">
              <v-btn large plain rounded :disabled="selectableFormat.length == 0" @click="switchReader">
                <v-icon dark>mdi-book-open-page-variant-outline</v-icon>
              </v-btn>
            </div>
          </v-row>


          <v-divider />
          <v-row justify="center">
            <v-col>
              <v-expansion-panels multiple :value="[0]" focusable flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>Tags</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card-text>
                      <v-row v-for="(entry, index) in tags" :key="index">
                        <v-col class="pa-1">
                          <v-chip color="light-blue lighten-1" class="mx-1 ml-1 mr-1">{{
                            entry[0]
                          }}</v-chip>
                          <v-chip class="ma-1" :color="((!tag.selected) ? ('red accent-1') : ('teal lighten-2'))"
                            v-for="(tag, index) in entry[1]" :key="index" @click="onTagClick(entry[0], tag)">{{ tag.name
                            }}</v-chip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Thumbnails</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <thumbnail :pages="currentBook.thumb?.items" @click="onClickPage" />
                    <v-list>
                      <v-list-item class="my-4">
                        <v-list-item-content>
                          <v-btn plain large @click="showFullThumb = true">
                            <span class="text-center">view more</span>
                          </v-btn>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Comments</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list two-line>
                      <v-list-item v-for="(comment, index) in comments" :key="index">
                        <v-list-item-content>
                          <v-list-item-title v-text="comment[0]" class="text-subtitle-1" />
                          <v-card-text v-text="comment[1]" class="text-body-2 text--secondary" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>
        <v-dialog v-model="showShelf" max-width="250">
          <!-- <v-toolbar>
          <v-btn icon @click="showShelf = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="title">Add Shelf</v-toolbar-title>
        </v-toolbar>
        <v-spacer/> -->
          <v-card>
            <v-list :disabled="shelfDisabled">
              <v-list-item v-for="(item, index) in shelfList" :key="index"
                @click="addRmBookInShelf(item, !item.contains)">
                <v-list-item-action>
                  <v-checkbox :input-value="item.contains" disabled></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="item.size">{{ item.name }} ({{ item.size }})</v-list-item-title>
                  <v-list-item-title v-else>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
        <book-thumb v-if="this.currentBook.thumb" v-model="showFullThumb" :getPage="loadThumb"
          :total="this.currentBook.length" :totalThumb="this.currentBook.thumb.totalPage" @click="onClickPage" />
      </template>
      <template v-else>
        <div class="d-flex justify-center align-center" style="height: 500px" v-if="loading">
          <v-progress-circular indeterminate color="primary" size="70"></v-progress-circular>
        </div>
        <div class="d-flex justify-center flex-column align-center" style="height: 500px" v-else>
          <v-icon size="90" color="red darken-2">mdi-close-circle</v-icon>
          <span class="pa-4">detail is empty, maybe the book is forbidden</span>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { StateStore } from '@/store/StateStore'
import { mapWritableState } from 'pinia'
import { ehWeb } from '@/components/web.js'
import ehData from "@/pages/ehViewer/ehData.js"
import Thumbnail from '@/components/Thumbnail.vue';
import BookThumb from '@/components/BookThumb.vue';

export default {
  name: "BookDetail",
  components: { Thumbnail, BookThumb },
  data: () => ({
    drawer: null,
    selectedFormat: '',
    shelfList: [],
    showShelf: false,
    shelfDisabled: false,
    tags: [],
    loadedBook: null,
    loading: true,
    showFullThumb: false
  }),
  props: ["book", "value", "loadDetail"],
  methods: {
    onClickPage(item) {
      this.$root.$reader.loadBook(this.currentBook.id, this.selectedFormat, this.currentBook, true, item.index)
      this.pageIndex = 'read';
      this.showFullThumb = false
      this.onInput(false)
    },
    splitTwoPart(str, split) {
      const index = str.indexOf(split)
      if (index < 0) {
        return null
      }
      const first = str.substring(0, index)
      const second = str.substring(index + split.length)
      return [first, second]
    },
    groupByTags(tags) {
      let map = {};
      for (const tag of tags) {
        const [first, second] = this.splitTwoPart(tag.name, ':')
        if (!map[first]) {
          map[first] = [];
        }
        let arr = map[first];
        let tag_ = Object.assign({}, tag)
        tag_['name'] = second
        arr.push(tag_);
      }
      return map
    },
    parseComments(comments) {
      let ret = []
      let current = comments
      let first = false
      current = current.replace('<div>', '').replace('</div>', '')
      for (; ;) {
        let title
        if (!first) {
          let split = this.splitTwoPart(current, "<p>")
          if (!split) break
          current = split[1]
          split = this.splitTwoPart(current, "</p>")
          if (!split) break
          [title, current] = split
        } else {
          first = false
          title = "Uploader"
        }

        let split = this.splitTwoPart(current, "<p>")
        if (!split) break
        current = split[1]
        split = this.splitTwoPart(current, "</p>")
        if (!split) break
        let comment
        [comment, current] = split
        ret.push([title, comment])
      }
      return ret
    },
    onInput(value) { this.$emit('input', value) },
    switchReader() {
      this.$root.$reader.loadBook(this.currentBook.id, this.selectedFormat, this.currentBook)
      this.pageIndex = 'read';
      this.onInput(false)
    },
    async loadShelfList() {
      let shelfList;
      if (this.currentBook.gid) {
        shelfList = await ehWeb.shelfList(this.currentBook.gid, this.currentBook.gtoken)
      } else {
        shelfList = await this.$hanaWeb.shelfList(this.currentBook.id, false)
      }
      this.shelfList = shelfList
      this.showShelf = true
    },
    async addRmBookInShelf(item, isAdd) {
      try {
        this.shelfDisabled = true
        if (this.currentBook.gid) {
          if (isAdd)
            await ehWeb.addToShelf(this.currentBook.gid, this.currentBook.gtoken, item.id)
          else
            await ehWeb.removeFromShelf(this.currentBook.gid, this.currentBook.gtoken)
        } else {
          if (isAdd)
            await this.$hanaWeb.addToShelf(item.id, this.currentBook.id)
          else
            await this.$hanaWeb.removeFromShelf(item.id, this.currentBook.id)
        }
      } finally {
        this.showShelf = false
        this.shelfDisabled = false
      }
      const text = isAdd ? `Book added to shelf ${item.name}` : `Book removed from shelf ${item.name}`
      this.$root.$snackBar.push({ text })
    },
    onTagClick(first, tag) {
      console.log(first, tag)
      if (!tag.selected) {
        this.$root.$tagList.addTag(first, tag.name)
      } else {
        this.$root.$tagList.removeTag(first, tag.name)
      }
      this.$set(tag, 'selected', !tag.selected)
      //tag.selected = !tag.selected // invalid in hana mode, because selected prop is not exist...
    },
    async loadThumb(pageIndex) {
      if (this.currentBook.pageIndex == pageIndex) {
        return this.currentBook.thumb.items
      }
      return (await ehData.getDetail(this.currentBook, pageIndex))?.thumb?.items || []
    }
  },
  computed: {
    currentBook() {
      return this.loadDetail ? this.loadedBook : this.book
    },
    tagGroup() {
      return this.currentBook ? (this.currentBook.groupedTags || this.groupByTags(this.currentBook.tags)) : {}
    },
    selectableFormat() {
      if (!this.currentBook) {
        return []
      }
      const support = new Set(['stream', 'cbz', 'exapi'])
      let ret = []
      for (let data of this.currentBook.data) {
        if (support.has(data.format.toLowerCase())) {
          ret.push(data)
        }
      }
      return ret
    },
    comments() {
      return this.currentBook.commentItems || this.parseComments(this.currentBook.comments)
    },
    ...mapWritableState(StateStore, ['pageIndex']),
  },
  mounted() {
  },
  watch: {
    async value(open) {
      if (open && !this.currentBook) {
        this.loading = true
        try {
          this.loadedBook = await this.loadDetail(this.book)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },
    loading() {
      if (this.loading) {
        return;
      }
      this.tags = []
      for (let entry of Object.entries(this.tagGroup)) {
        let tagsInGroup = []
        for (let tag of entry[1]) {
          if (tag.name.trim() == "") {
            continue
          }
          tagsInGroup.push(tag)
        }
        if (tagsInGroup.length > 0) {
          this.tags.push([entry[0], tagsInGroup])
        }
      }
      const selectable = this.selectableFormat
      if (selectable.length == 0) {
        return
      }
      //console.log(this.currentBook.title, selectable)
      for (const data of selectable) {
        const format = data.format.toLowerCase()
        if (format == 'stream') {
          this.selectedFormat = 'stream'
          return
        }
      }
      this.selectedFormat = selectable[0].format;
    }
  }
};
</script>
<template>
  <v-card>
    <v-card-text>
      <v-list>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>currentUser</v-list-item-title>
            <v-list-item-subtitle>{{ currentUser }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>accessToken</v-list-item-title>
            <v-list-item-subtitle>{{ accessToken }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Storage Detail</v-list-item-title>
            <v-list-item-subtitle>IndexedDB: {{ indexedDbStatus }} Quota: {{ storageQuota }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Cached Image</v-list-item-title>
            <v-list-item-subtitle>{{cachedImageSize}} / {{ maxCachedSizeStr }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Cached Meta</v-list-item-title>
            <v-list-item-subtitle>{{cachedMeta}} Items</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="innerPreloadPages"
              required
              label="PreloadPages"
              type="number"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="innerBaseURL"
              required
              label="BaseURL"
              :rules="[(val) => (val || '').length > 0 || 'This field is required']"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="innerEhURL"
              required
              label="BaseEhURL"
              :rules="[(val) => (val || '').length > 0 || 'This field is required']"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="innerExCookies"
              required
              label="exCookies"
            ></v-text-field>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon large @click="checkEhStatus" :disabled="checkingEhStatus">
              <v-icon>mdi-autorenew</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-select :disable="changingEhProfile" label="e-Hentai profiles" :value="selectedEhProfiles" @input="onChangeEhProfile" v-model="selectedEhProfiles" :items="ehProfiles">
            </v-select>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><v-switch :value="darkMode" @change="(val) => this.$vuetify.theme.dark = this.darkMode = val" label="Dark Mode">
            </v-switch></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="saveConfig">
          <v-list-item-content>
            <v-list-item-title>
              Click me to dump Config
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-content>
              <v-file-input
                accept="text/plain;charset=utf-8"
                label="Restore Config"
                @change="openConfFile"
              ></v-file-input>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Version</v-list-item-title>
            <v-list-item-subtitle>{{ versionStr }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn large @click="saveData"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { StateStore } from "@/store/StateStore";
import { mapWritableState, mapState, mapActions } from "pinia";
import {filesize} from "filesize";
import { showEstimatedQuota } from "@/components/Storage.js"
import { db } from '@/components/db.js'
import version from '@/version.js'
import { saveAs } from 'file-saver';

export default {
  name: "Setting",
  components: {},
  data: () => ({
    innerBaseURL: "",
    innerEhURL: "",
    storageQuota: "N/A",
    indexedDbStatus: 'N/A',
    cachedImageSize: '',
    cachedMeta: 0,
    innerExCookies: '',
    innerPreloadPages: 0,
    checkingEhStatus: false,
    changingEhProfile: false,
    selectedEhProfiles: null,
    ehProfiles: []
  }),
  props: [],
  methods: {
    ...mapActions(StateStore, ['dumpConfig', 'restoreConfig']),
    saveData() {
      this.baseURL = this.innerBaseURL;
      this.baseEhURL = this.innerEhURL
      this.exCookies = this.innerExCookies
      this.preloadPages = Number(this.innerPreloadPages)
    },
    async showEstimatedQuota() {
      const status = await showEstimatedQuota()
      console.log(status)
      const usage = (status?.usage != null && filesize(status.usage, {base: 2, standard: "jedec"})) || 'N/A'
      const quota = (status?.quota != null && filesize(status.quota, {base: 2, standard: "jedec"})) || 'N/A'
      this.storageQuota = `${usage} / ${quota}`
    },
    async countCachedImage() {
      let total = 0
      await db.CachedImage.each(e => total += e.size)
      this.cachedImageSize = filesize(total, {base: 2, standard: "jedec"})
    },
    async countCachedMeta() {
      this.cachedMeta = await db.WorkReadMeta.count()
    },
    async checkEhStatus() {
      try {
        this.checkingEhStatus = true
        const items = await this.$ehWeb.getConfig()
        let profiles = []
        for (let item of items.profiles) {
          profiles.push({value: item.value, text: item.name})
          if (item.selected) {
            this.selectedEhProfiles = item.value
          }
        }
        this.ehProfiles = profiles
        this.$root.$snackBar.push({text: 'E-hentai logined'})
      } catch(e) {
        this.$root.$snackBar.push({text: e})
      } finally {
        this.checkingEhStatus = false
      }
    },
    async onChangeEhProfile(value) {
      try {
        this.changingEhProfile = true
        await this.$ehWeb.modifyConfig(value)
        this.$root.$snackBar.push({text: 'E-Hentai profile changed'})
      } catch(e) {
        this.$root.$snackBar.push({text: e})
      } finally {
        this.changingEhProfile = false
      }
    },
    saveConfig() {
      const blob = new Blob([JSON.stringify(this.dumpConfig())], {type: "text/plain;charset=utf-8"});
      saveAs(blob, 'config.json')
    },
    openConfFile(file) {
      const reader = new FileReader()
      reader.addEventListener('load', (e) => {
        try {
          const data = JSON.parse(e.target.result)
          this.restoreConfig(data)
          this.refresh()
          this.$root.$snackBar.push({ text: 'restore config success.' })
        } catch(ex) {
          console.error(ex)
        }
        })
      reader.readAsText(file, 'utf-8')
    },
    refresh() {
      this.innerBaseURL = this.baseURL;
      this.innerEhURL = this.baseEhURL
      this.innerExCookies = this.exCookies
      this.innerPreloadPages = this.preloadPages
      this.showEstimatedQuota()
      const indexeddb = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB
      this.indexedDbStatus = indexeddb ? 'support' : 'unsupport'
      this.countCachedImage()
      this.countCachedMeta()
    }
  },
  computed: {
    ...mapState(StateStore, ["currentUser", "accessToken", 'maxCachedSize']),
    ...mapWritableState(StateStore, ["baseURL", "baseEhURL", 'exCookies', 'preloadPages', 'darkMode']),
    versionStr() {
      return version
    },
    maxCachedSizeStr() {
      return filesize(this.maxCachedSize, {base: 2, standard: "jedec"})
    }
  },
  async created() {
    this.refresh()
  },
};
</script>
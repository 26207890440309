<template>
  <v-snackbar v-model="value" :timeout="timeout" multi-line class="bottom10">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        @click="value = false; closeCallback()"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  mounted() {
    this.$root.$snackBar = this
  },
  components: {},
  data: () => ({
    value: false,
    text: '',
    timeout: 4000,
    promise: null,
    closeCallback: () => {}
  }),
  methods: {
    push({text, timeout = 4000} = {}) {
      this.promise = (async () => {
        try {
          if (this.promise) {
            await this.promise
          }
        } finally {
          await this.doTask({text, timeout})
        }
      })();
    },
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    async doTask({text, timeout}) {
      this.text = text
      this.timeout = timeout
      this.value = true
      await Promise.race([this.sleep(timeout + 500), new Promise((resolve) => {
        this.closeCallback = resolve
      })])
    }
  }
}
</script>

<style>
.bottom10 {
  bottom: 10%
}
</style>
<template>
  <v-bottom-sheet :value="value" @input="onInput">
    <v-list>
      <v-list-item v-for="(tag, index) in tagList" :key="`${tag.tag[0]}-${tag.tag[1]}`">
        <v-list-item-content :class="tag.neg ? 'text--disabled' : ''"> {{ tag.tag[0] }}:{{ tag.tag[1] }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="onThumb(index, !tag.neg)">
            <v-icon>{{ ((!tag.neg) ? ("mdi-thumb-down-outline") : ("mdi-thumb-up-outline")) }}</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <v-btn icon @click="removeItem(index)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn plain @click="removeAll">
            clear
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-list-item-content>
        <v-list-item-content>
          <v-btn plain @click="onSavePattern">
            save
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-content>
        <v-list-item-content>
          <v-btn plain @click="onSearch()">
            search
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="showDialog" max-width="600">
      <v-card>
        <v-card-title>Save Pattern</v-card-title>
        <v-card-text>
          <div>
            <v-text-field clearable label="Name" v-model="patternName" :rules="[value => !!value || 'Required.']">
            </v-text-field>
            <v-text-field clearable required label="Pattern" :rules="[value => !!value || 'Required.']" v-model="pattern">
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onSaveBtn">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-bottom-sheet>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { StateStore } from "@/store/StateStore";

export default {
  props: ["value"],
  mounted() {
    this.$root.$tagList = this
  },
  components: {},
  data: () => ({
    showDialog: false,
    pattern: '',
    patternName: '',
  }),
  methods: {
    onSavePattern() {
      this.openSavePattern(this.searchText(false, true), this.searchText(this.viewMode == 'exapi', false))
    },
    openSavePattern(name, pattern) {
      this.showDialog = true
      this.pattern = pattern
      this.patternName = name
    },
    onSaveBtn() {
      if (this.pattern && this.patternName) {
        this.searchPatterns.push([this.patternName, this.pattern])
      }
      this.showDialog = false
    },
    onInput(value) {
      this.$emit("input", value);
    },
    onThumb(index, neg) {
      const newItem = Object.assign({}, this.tagList[index])
      newItem.neg = neg
      this.$set(this.tagList, index, newItem)
    },
    removeItem(index) {
      this.tagList.splice(index, 1)
    },
    removeAll() {
      this.tagList = []
    },
    onSearch(text = '') {
      if (this.viewMode == 'hana') {
        this.$router.push({ path: '/search/' + (!text ? this.searchText(false) : text) })
        this.onInput(false)
      }
      if (this.viewMode == 'exapi') {
        console.log(this.searchText(true), text)
        this.$router.push({ name: 'ehsearch', params: { pattern: (!text ? this.searchText(true) : text) } })
        this.onInput(false)
      }
    },
    findTag(first, second) {
      let index = -1
      for (let i = 0; i < this.tagList.length; ++i) {
        const item = this.tagList[i]
        if (item.tag[0] == first && item.tag[1] == second) {
          index = i
          break
        }
      }
      return index
    },
    addTag(first, second) {
      if (this.findTag(first, second) < 0) {
        this.tagList.push({ tag: [first, second], neg: false })
      }
    },
    removeTag(first, second) {
      const index = this.findTag(first, second)
      this.removeItem(index)
    },
    searchText(exMode = false, dense = false) {
      let text = ''
      for (let i = 0; i < this.tagList.length; ++i) {
        if (i > 0)
          text += ' '
        if (this.tagList[i].neg)
          text += '-'
        const firstPart = dense ? String(this.tagList[i].tag[0][0]) : this.tagList[i].tag[0]
        const secondPart = this.tagList[i].tag[1]
        if (!exMode) {
          text += firstPart + `:"${secondPart}"`
        } else {
          text += firstPart + `:"${secondPart}$"`
        }
      }
      return text
    },
  },
  computed: {
    ...mapWritableState(StateStore, ['tagList', 'searchPatterns']),
    ...mapState(StateStore, ['viewMode']),
  },
  watch: {
  }
};
</script>

<style></style>
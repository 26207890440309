<template>
  <div>
    <div class="d-flex justify-center align-center pa-3">
      <slot name="button-group">
        <v-btn-toggle v-model="selectedOrder" borderless mandatory color="primary" tile>

          <v-btn value="new">
            <span class="hidden-sm-and-down">new</span>

            <v-icon small>
              mdi-order-numeric-descending
            </v-icon>
          </v-btn>

          <v-btn value="old">
            <span class="hidden-sm-and-down">old</span>

            <v-icon small>
              mdi-order-numeric-ascending
            </v-icon>
          </v-btn>

          <v-btn value="pubnew">
            <span class="hidden-sm-and-down">pub new</span>

            <v-icon small>
              mdi-order-numeric-descending
            </v-icon>
          </v-btn>

          <v-btn value="pubold">
            <span class="hidden-sm-and-down">pub old</span>

            <v-icon small>
              mdi-order-numeric-ascending
            </v-icon>
          </v-btn>
          <slot name="new-button" :selected="selectedOrder"></slot>
        </v-btn-toggle>
      </slot>
    </div>
    <v-row v-if="!$vuetify.breakpoint.mobile">
      <v-col v-for="book of books" :key="book.id" md="4" xl="2" lg="3" class="pa-5">
        <book-card v-bind:book="book" :loadDetail="loadDetail"></book-card>
      </v-col>
    </v-row>
    <v-list v-else three-line class="py-0">
      <book-list-item v-for="book of books" :key="book.id" :book="book" :loadDetail="loadDetail" />
    </v-list>
    <div v-intersect="onIntersect">
      <slot name="pagination">
        <div class="pa-4" v-if="pagination">
          <v-pagination :value="pagination.page" :length="pagination.totalPage" @input="update"
            total-visible="5"></v-pagination>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import BookCard from "./BookCard";
import BookListItem from "@/components/BookListItem"
import { StateStore } from "@/store/StateStore";
import { mapWritableState } from "pinia";


export default {
  name: "BookView",
  components: {
    BookCard,
    BookListItem
  },
  data: () => ({
    drawer: null,
    selectedOrder: 'new',
  }),
  props: ["books", "pagination", "loadDetail"],
  methods: {
    update: function (page) {
      this.$emit('update', {
        order: this.selectedOrder,
        nextPage: page,
      })
    },
    onIntersect(entries) {
      const onBack = entries[0].isIntersecting && this.books?.length > 0
      this.$emit('scrollToBack', onBack)
      this.scrollToBack = onBack
    },
  },
  watch: {
    selectedOrder() {
      this.update(1)
    }
  },
  computed: {
    ...mapWritableState(StateStore, ['scrollToBack'])
  }
};
</script>
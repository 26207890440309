import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import zhHans from 'vuetify/lib/locale/zh-Hans';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { zhHans },
      current: 'zhHans',
    },
    breakpoint: {
      mobileBreakpoint: 'sm' // 960
    },  
    theme: {
      themes: {
        light: {
          background: colors.grey.lighten3
        },
        dark: {
          background: colors.grey.darken3
        }
      },
    }
});

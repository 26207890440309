import { ehWeb } from '@/components/web.js'
import storage from '@/pages/Reader/CachedStorage'

const ehData = {

  exportTitle(title) {
    const prefix = new RegExp("^(?:(?:\\([^\\)]*\\))|(?:\\[[^\\]]*\\])|(?:\\{[^\\}]*\\})|(?:~[^~]*~)|\\s+)*", 'i')
    const suffix = new RegExp("(?:\\s+ch.[\\s\\d-]+)?(?:(?:\\([^\\)]*\\))|(?:\\[[^\\]]*\\])|(?:\\{[^\\}]*\\})|(?:~[^~]*~)|\\s+)*$", 'i')
    title = title.replace(prefix, '').replace(suffix, '')
    const result = title.split('|')
    if (result.length >= 2 && result[result.length - 1].length > 0) {
      return result[result.length - 1]
    }
    return title
  },

  genWorkKey(gid, gtoken) {
    return `eh-${gid}-${gtoken}`
  },

  async gallery(gid, gtoken, page=0) {
    console.log('load eh gallery', gid, gtoken, page)
    const gallery = await ehWeb.gallery(gid, gtoken, page)
    if (!gallery) {
      return gallery
    }
    if (gallery.imgHref) {
      await storage().setWorkMeta(this.genWorkKey(gid, gtoken), String(page), gallery.imgHref)
    }
    return gallery
  },

  async getImgHref(gid, gtoken, page=0) {
    return (await storage().getWorkMeta(this.genWorkKey(gid, gtoken), String(page))) || (await this.gallery(gid, gtoken, page))?.imgHref
  },

  async getDetail(book, page=0) {
    const detail = await this.gallery(book.gid, book.gtoken, page)
    if (!detail) { //gallery is forbidden
      return
    }
    console.log(detail)
    detail.id = this.genWorkKey(book.gid, book.gtoken)
    detail.gid = book.gid
    detail.gtoken = book.gtoken
    detail.shorten_title = this.exportTitle(detail.title)
    detail.authors = []
    if (detail.tags["artist"]) {
      for (let author of detail.tags["artist"]) {
        detail.authors.push({ name: author })
      }
    }
    detail.mark = `${detail.type}/${detail.length}P`
    detail.data = [{ 'format': 'exapi' }]
    detail.pubdate = detail.published
    const tags = {}
    for (let [key, tagsList] of Object.entries(detail.tags)) {
      const tagList = []
      for (let tag of tagsList) {
        tagList.push({ name: tag, selected: false })
      }
      tags[key] = tagList
    }

    if (detail.imgHref) {
      await storage().setWorkMeta(this.genWorkKey(book.gid, book.gtoken), String(page), detail.imgHref)
    }
    // Array -> Map<pageIndex,Array>
    const href0 = detail.imgHref
    detail.imgHref = new Map([['' + page, href0]])

    detail.groupedTags = tags
    detail.commentItems = []
    for (const comment of detail.comments) {
      const date = new Date(comment.time * 1000)
      let title = `${comment.user} ${comment.isuploader ? '(uploader)' : `${comment.score > 0 ? '+' : ''}${comment.score}`} ${date.toLocaleString()}`
      detail.commentItems.push([title, comment.text])
    }
    const thumb = detail.thumb
    const startIndex = parseInt(href0[0][1].split('-')[1])
    let thumbItem = []
    if (thumb.merged) {
      for (let i = 0; i < thumb.style.length; ++i) {
        thumbItem.push({img: thumb.style[i], index:  startIndex + i - 1, merged: true})
      }
    } else {
      for (let i = 0; i < thumb.url.length; ++i) {
        thumbItem.push({img: thumb.url[i], index:  startIndex + i - 1, merged: false})
      }
    }
    detail.thumb.items = thumbItem
    book.detail = detail
    return detail
  },


  toLangMark(language) {
    switch(language.toLowerCase()) {
    case 'chinese':
      return 'CN'
    case 'english':
      return 'EN'
    case 'japanese':
      return 'JP'
    default:
      return language.substr(0, 2).toUpperCase()
    }
  },

  async getBooks(pattern, nextGid, isNext) {
    const data = await ehWeb.search(pattern, nextGid, isNext)
    for (let book of data) {
      book.id = this.genWorkKey(book.gid, book.gtoken)
      book.shorten_title = this.exportTitle(book.title)
      book.authors = [{name: book.uploader}]
      let headTags = {}
      for (let tag of book.headTags) {
        if (!headTags[tag[0]]) {
          headTags[tag[0]] = []
        }
        headTags[tag[0]].push({name: tag[1], display: tag[2]})
      }
      this.headTags = headTags
      book.mark = `${book.type}/${book.length}P`
      if (this.headTags['language']) {
        book.mark = `${this.toLangMark(this.headTags['language'][0].name)}/` + book.mark
      }
      book.data = [{'format': 'exapi'}]
    }
    return data
  },
}

export default ehData;
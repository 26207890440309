<template>
  <v-list-item @click="onClick">
    <v-img :src="book.cover || $hanaWeb.bookCoverURL(book.id)" height="100" max-width="70"
      class="mr-4 my-2 background" contain />
    <v-list-item-content>
      <v-list-item-title>{{ book.shorten_title }}</v-list-item-title>
      <v-list-item-subtitle>
        <span v-for="(author, index) in book.authors" :key="index">
          {{ author.name }}
          <span v-if="index >= 0 && index < book.authors.length - 1">/</span>
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ book.mark }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon @click="onAddReadList" :disabled="defaultSelected == null || inReadList">
        <v-icon>mdi-book-plus-multiple-outline</v-icon>
      </v-btn>
    </v-list-item-action>
    <book-detail v-model="showDetail" :book="book" :loadDetail="loadDetail" />
  </v-list-item>
</template>

<script>
import BookDetail from '@/components/BookDetail'
import { StateStore } from '@/store/StateStore'
import { mapWritableState, mapActions } from 'pinia'

export default {
  props: ['book', 'loadDetail'],
  mounted() {
  },
  components: {
    BookDetail
  },
  data: () => ({
    showDetail: false,
    clickBtn: false,
    inReadList: false
  }),
  methods: {
    ...mapActions(StateStore, ['addToReadList', 'removeFromReadList', 'existsReadList']),
    onClick() {
      if (!this.clickBtn)
        this.showDetail = true
      else
        this.clickBtn = false
    },
    onAddReadList() {
      this.clickBtn = true
      this.$root.$reader.loadBook(this.book.id, this.defaultSelected, this.book, false)
    }
  },
  computed: {
    ...mapWritableState(StateStore, ['readListChanged']),
    selectableFormat() {
      const support = new Set(['stream', 'cbz', 'exapi'])
      let ret = []
      for (let data of this.book.data) {
        if (support.has(data.format.toLowerCase())) {
          ret.push(data)
        }
      }
      return ret
    },
    defaultSelected() {
      const selectable = this.selectableFormat
      if (selectable.length == 0) {
        return null
      }
      for (const data of selectable) {
        const format = data.format.toLowerCase()
        if (format == 'stream') {
          return 'stream'
        }
      }
      return selectable[0].format
    }
  },
  watch: {
    readListChanged() {
      this.inReadList = this.existsReadList(this.book.id)
    }
  }
};
</script>

<style></style>
<template>
  <div>
    <fullscreen v-model="isFullscreen">
      <div :ref="'images-wrapper'" class="images-wrapper d-flex align-center flex-column" :key="'images-wrapper'"
        :id="'images-wrapper'" @load="init">
        <div :style="'width: ' + imageDivWidth + '%;'">
          <!-- <div v-show="renderPages.length > 0 && renderPages[0].index > 0">
          <v-sheet
            color="green lighten-3"
            elevation="1"
            height="50"
            width="100%"
            class="d-flex justify-center align-center"
          >
            <v-progress-circular indeterminate color="white" />
          </v-sheet>
        </div> -->

          <div v-for="item of renderPages" :key="item.index" :ref="'image-' + item.index" class="image-wrapper">
            <img v-if="item.url != ''" :src="item.url" @load="refresh" />
            <div v-if="item.url == ''" class="prep-image justify-center align-center d-flex flex-column">
              <span class="text-h2 white--text text-center font-weight-medium pa-8">
                {{ item.index + 1 }}
              </span>
              <v-progress-circular v-if="!item.ctx || !item.ctx.error"
                :indeterminate="!item.ctx || item.ctx.progress.loaded == 0" color="primary" size="70" rotate="-90"
                :value="(!item.ctx) ? (0) : (item.ctx.progress.loaded / item.ctx.progress.total * 100)"></v-progress-circular>
              <template v-else>
                <span class="text-subtitle-1 red--text text--lighten-1 font-weight-medium">
                  {{ item.ctx.error.message }}
                </span>
                <v-btn link plain class="white--text" @click="trunPage(item.index)">
                  retry
                </v-btn>
              </template>
            </div>
          </div>
          <!-- <div
          v-show="
            renderPages.length > 0 &&
            renderPages[renderPages.length - 1].index < pages.length - 1
          "
        >
          <v-sheet
            color="green lighten-3"
            elevation="1"
            height="50"
            width="100%"
            class="d-flex justify-center align-center"
          >
            <v-progress-circular indeterminate color="white" />
          </v-sheet>
        </div> -->
        </div>
      </div>
      <div class="control-panel" v-show="showPanel">
        <v-dialog max-width="300" v-model="showSkipPage">
          <v-card>
            <v-card-title> Select Page you want go to </v-card-title>
            <v-card-text>
              <v-slider thumb-label :value="skipPage + 1" @input="(num) => (skipPage = num - 1)" min="1"
                :max="pages.length">
                <template v-slot:append>
                  <v-text-field :value="skipPage + 1" @input="(num) => (skipPage = num - 1)" class="mt-0 pt-0"
                    type="number" style="width: 60px"></v-text-field>
                </template>
              </v-slider>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn plain class="pa-0" @click="
                currentPage = skipPage;
              trunPage(skipPage);
              showSkipPage = false;
              ">
                Jump!
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="d-flex align-center">
          <v-slider thumb-label :value="currentPage + 1"
            @input="(num) => { currentPage = num - 1; trunPage(currentPage); }" min="1" :max="pages.length" class="mt-1">
            <template v-slot:append>
              <v-menu top v-if="!$vuetify.breakpoint.mobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn plain rounded dark v-bind="attrs" v-on="on">
                    <v-icon dark>{{ mdiMagnifyPlusOutline }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-slider dense :value="imageDivWidth" @input="setImageDivWidth" vertical min="10" max="100"
                        step="10" ticks>
                        <template v-slot:prepend>
                          <span class="text--secondary text-center ml-2">{{ imageDivWidth }}%</span>
                        </template>
                      </v-slider>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn plain rounded dark @click="skipPage = currentPage; showSkipPage = true">
                <div class="font-weight-bold">
                  {{ currentPage + 1 }} / {{ pages.length }}
                </div>
              </v-btn>
            </template>
          </v-slider>
        </div>
        <div class="d-flex justify-center">
          <v-btn large plain rounded dark
            v-show="renderPages.length > 0 && renderPages[0].index - 1 >= 0 && currentPage <= renderPages[0].index + 1"
            @click="currentPage = renderPages[0].index - 1; trunPage(currentPage);">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn large plain rounded dark @click="isFullscreen = !isFullscreen" v-if="supportFullScreen">
            <v-icon dark>{{ isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
          </v-btn>
          <v-btn large plain rounded dark @click="
            zoomTo(1);
          " v-show="zoomScale != 1">
            <v-icon dark>mdi-border-outside</v-icon>
          </v-btn>
          <v-btn large plain rounded dark @click="showReadList = true">
            <v-icon dark>mdi-view-array</v-icon>
          </v-btn>
          <v-btn large plain rounded dark @click="showThumb = true" v-show="this?.currentBook?.item?.thumb">
            <v-icon dark>mdi-view-grid-outline</v-icon>
          </v-btn>
          <v-btn large plain rounded dark
            v-show="renderPages.length > 1 && renderPages[renderPages.length - 1].index + 1 <= pages.length - 1 && currentPage >= renderPages[renderPages.length - 2].index"
            @click="currentPage = renderPages[renderPages.length - 1].index + 1; trunPage(currentPage);">
            <v-icon dark>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-dialog v-model="showReadList" :value="value" :fullscreen="$vuetify.breakpoint.mobile"
        :max-width="!$vuetify.breakpoint.mobile ? '1500' : undefined">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-btn icon @click="showReadList = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="title">Switch Book</v-toolbar-title>
          </v-toolbar>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Memory Cache: {{ fileSize(lruBlob.size) }} / {{ fileSize(lruBlob.limit) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list three-line>
            <v-list-item-group>
              <read-list-item v-for="[bookID, record] of Object.entries(readMap)" :key="bookID" :bookID="bookID"
                :record="record"
                @switchBook="(({ bookID, format, item }) => { loadBook(bookID, format, item); showReadList = false; })"
                @closeBook="record => closeBook(record)" />
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-dialog>
      <book-thumb v-if="this?.currentBook?.item?.thumb" v-model="showThumb" :getPage="loadThumb"
        :total="this?.currentBook?.item?.length" :totalThumb="this?.currentBook?.item?.thumb?.totalPage"
        @click="(item) => { trunPage(item.index); showThumb = false }" />
      <div class="pagination" v-show="showPagination"><span>{{ paginationText }}</span></div>
    </fullscreen>
    <v-row
      v-resize="onResize"
    />
  </div>
</template>

<script>
import BScroll from "@better-scroll/core";
import MouseWheel from "@better-scroll/mouse-wheel";
import PullDown from "@better-scroll/pull-down";
import Pullup from "@better-scroll/pull-up";
import ObserveDOM from "@better-scroll/observe-dom";
import Zoom from "@better-scroll/zoom";
import ObserveImage from '@better-scroll/observe-image'
import JSZip from "jszip";
import { StateStore } from '@/store/StateStore'
import { mapWritableState, mapActions, mapState } from 'pinia'
import ChunkManager from "@/pages/Reader/ChunkManager.js";
import ehPageManager from "@/pages/Reader/EhPageManager.js";
import ehData from "@/pages/ehViewer/ehData.js"
import BookThumb from "@/components/BookThumb.vue";

import ReadListItem from '@/pages/Reader/ReadListItem'

import { LruBlob } from "@/components/LruBlob.js";
import { throttle } from "@/components/utils.js";
import { filesize } from "filesize";
import { mdiMagnifyPlusOutline } from '@mdi/js';

BScroll.use(MouseWheel);
BScroll.use(PullDown);
BScroll.use(Pullup);
BScroll.use(ObserveDOM);
BScroll.use(Zoom);
BScroll.use(ObserveImage);

class BookRecord {
  constructor(bookID, format, item, pageManager = null) {
    this.bookID = bookID;
    this.format = format;
    this.currentPage = 0
    this.item = item
    if (pageManager) {
      this.pageManager = pageManager;
      this.pages = this.pageManager.pages;
    } else {
      this.pages = [];
    }
  }

  destroy() {
    if (this.pageManager) {
      this.pageManager.destroy();
    } else {
      for (const page of this.pages) {
        window.URL.revokeObjectURL(page.url);
      }
    }
  }
}

const maxCacheSize = 40 * 1024 * 1024

export default {
  name: "Reader",
  components: {
    ReadListItem,
    BookThumb
  },
  data: () => ({
    pages: [],
    renderPages: [],
    currentPage: 0,
    scroll: null,
    selfChangePage: 0,
    zoomScale: 1,
    showSkipPage: false,
    skipPage: 0,
    lruBlob: new LruBlob(maxCacheSize),
    readMap: {},
    currentBook: null,
    showReadList: false,
    showDetail: false,
    detailBook: null,
    isFullscreen: false,
    showThumb: false,
    imageDivWidth: 100,
    panelSwitch: true,
  }),
  props: ["readBooks", "value", "fullscreen", "maxWidth"],
  methods: {
    ...mapActions(StateStore, ['addToReadList', 'removeFromReadList']),
    fileSize(number) {
      return filesize(number, { base: 2, standard: "jedec" })
    },
    onInput(value) {
      this.$emit("input", value);
    },
    refresh() {
      console.log("refresh");
      this.scroll.refresh();
      //this.scroll.scrollToElement(this.$refs["image-" + this.currentPage][0]);
    },
    setPanelSwitch(option) {
      this.panelSwitch = option
    },
    trunPage(pageIndex) {
      if (!this.currentBook) {
        return
      }
      this.currentPage = pageIndex
      this.rerender()
      setTimeout(() => {
        const ref = this.$refs["image-" + pageIndex]?.[0]
        if (ref) {
          console.log('trun page', pageIndex)
          this.scroll.scrollToElement(ref);
        } else {
          console.log('xxx')
        }
      }, 100)
    },
    imagesScroll() {
      if (this.renderPages.length == 0) {
        return;
      }
      let containerHalfHeight = this.$refs["images-wrapper"].offsetHeight / 2;
      //计算页数，图片底部位置不足半个页面，计算为下一页。
      let index = 0;
      let pageIndex = 0;
      for (; index < this.renderPages.length; index++) {
        pageIndex = this.renderPages[index].index;
        if (
          this.$refs["image-" + pageIndex][0].getBoundingClientRect().bottom >
          containerHalfHeight
        ) {
          break;
        }
      }
      console.log("currentPage", this.currentPage, "pageIndex", pageIndex);
      console.log(
        pageIndex,
        this.$refs["image-" + pageIndex][0].getBoundingClientRect().bottom
      );
      console.log(`list index ${pageIndex}`);
      this.selfChangePage = pageIndex;
      console.log("滾動：" + pageIndex);
      this.currentPage = pageIndex;
    },
    rerender() {
      const maxRender = 50;
      const lower = this.currentPage - (this.currentPage % maxRender);
      const upper = lower + maxRender;
      console.log("render", lower, ",", upper);
      // console.log(this.pages);
      let newRenderPages = this.pages.slice(lower, upper);
      this.renderPages = newRenderPages;
      this.scroll.refresh();
      this.selfChangePage = this.currentPage;
      if (this.currentBook.pageManager) {
        this.currentBook.pageManager.loadPage(this.currentPage);
      }
    },
    async addReadList(bookID, format, bookItem, jsonData = null) {
      if (bookID in this.readMap) {
        return;
      }
      switch (format.toLowerCase()) {
        case "cbz":
          this.$set(this.readMap, bookID, new BookRecord(bookID, format, bookItem))
          this.addToReadList(bookID, { bookID, format })
          break;
        case "stream": {
          if (!jsonData) {
            const file = await (this.$downloader.download(
              this.$hanaWeb.bookDataURL(bookID, format)
            ).promise);
            jsonData = JSON.parse(await file.text())
          }
          const pageManager = ChunkManager.fromJson(jsonData, bookID, this.$downloader, this.lruBlob, this)
          console.log(pageManager);
          this.$set(this.readMap, bookID, new BookRecord(bookID, format, bookItem, pageManager))
          this.addToReadList(bookID, { bookID, format, jsonData: pageManager.toJson() })
          break;
        }
        case 'exapi': {
          let manager;
          if (jsonData == null && bookItem?.detail == null) {
            const detail = await ehData.getDetail(bookItem)
            manager = new ehPageManager(detail, this.lruBlob, this.$downloader, this)
          } else if (jsonData == null) {
            if (!(bookItem.detail.imgHref instanceof Map)) { // I dont' know
              bookItem.detail.imgHref = new Map(Object.entries(bookItem.detail.imgHref))
            }
            const detail = bookItem.detail
            if (!detail.imgHref.has('0')) {
              const href0 = await ehData.getImgHref(bookItem.gid, bookItem.gtoken, 0)
              if (href0) {
                detail.imgHref.set('0', href0)
              }
            }
            manager = new ehPageManager(bookItem.detail, this.lruBlob, this.$downloader, this)
          } else {
            manager = ehPageManager.fromJson(jsonData, this.lruBlob, this.$downloader, this)
          }
          console.log(manager)
          this.$set(this.readMap, bookID, new BookRecord(bookID, format, manager.detail, manager))
          this.addToReadList(bookID, { bookID, format, jsonData: manager.toJson() })
          break;
        }
        default: {
          throw Error('unknown format', format)
        }
      }
    },
    switchBook(book) {
      if (this.currentBook) {
        this.currentBook.currentPage = this.currentPage
        console.log(this.currentBook, this.readMap)
      }
      this.currentBook = book;
      this.pages = book.pages;
      this.currentPage = this.currentBook.currentPage
      this.selfChangePage = this.currentPage;
      this.trunPage(this.currentPage)
    },
    async loadBook(bookID, format, bookItem, Switch = true, switchPage = null) {
      if (this.currentBook && this.currentBook.bookID == bookID) {
        if (switchPage) {
          console.log(switchPage)
          this.trunPage(switchPage)
        }
        return;
      }
      await this.addReadList(bookID, format, bookItem);
      const book = this.readMap[bookID];
      switch (book.format.toLowerCase()) {
        case "cbz":
          // not load, load it...
          if (book.pages.length == 0) {
            const file = await (this.$downloader.download(
              this.$hanaWeb.bookDataURL(bookID, format)
            ).promise);
            book.pages = await this.readPicInZip(file);
            for (let page of book.pages) {
              page.url = window.URL.createObjectURL(page.blob);
              delete page.blob;
            }
          }
          break;
        case "stream":
          break;
      }
      if (switchPage) {
        book.currentPage = switchPage
      }
      if (Switch) {
        this.switchBook(book);
      }
    },
    closeBook(record) {
      this.pages = []
      this.currentPage = this.selfChangePage = 0
      this.renderPages = []
      this.currentBook = null
      record.destroy()
      delete this.readMap[record.bookID]
      this.removeFromReadList(record.bookID)
      console.log(this.readList)
    },
    isImg(name) {
      if (name == null) {
        return false;
      }
      const ext = [".png", ".webp", ".bmp", ".jpg", ".jpeg", ".gif"];
      for (let e of ext) {
        if (name.toLowerCase().endsWith(e)) {
          return true;
        }
      }
      return false;
    },
    sortBlobList(blobList) {
      blobList.sort((a, b) => {
        return ("" + a.name).localeCompare(b.name);
      });
    },
    async readPicInZip(file, sort = true) {
      let blobList = [];
      //sortBlobList
      // let promise = [];
      const zip = await JSZip.loadAsync(file);
      let entries = zip.filter(this.isImg);
      if (sort) {
        entries.sort((a, b) => {
          return ("" + a.name).localeCompare(b.name);
        });
      }

      console.log(entries);
      let index = 0;
      for (let entry of entries) {
        console.log(`extract ${entry.name}`);
        let blob = await entry.async("blob");
        blobList.push({
          name: entry.name,
          blob,
          index,
        });
        index += 1;
      }
      return blobList;
    },
    pullingUpHandler() {
      console.log("pull up");
      if (this.currentPage >= this.pages.length - 1) {
        return;
      }
      this.currentPage += 1;
      //this.rerender();
      this.scroll.finishPullUp();
    },
    pullingDownHandler() {
      console.log("pull down");
      if (this.currentPage <= 0) {
        return;
      }
      this.currentPage -= 1;
      //this.rerender();
      this.scroll.finishPullDown();
    },
    zoomTo(value) {
      this.scroll.zoomTo(value, "center", "center");
    },
    onResize() {
      console.log('resize')
      this.trunPage(this.currentPage)
    },
    async init() {
      this.imageDivWidth = this.initialImageDivWidth
      console.log(this.$refs["images-wrapper"]);
      this.scroll = new BScroll(this.$refs["images-wrapper"], {
        click: true,
        mouseWheel: {
          speed: 20,
          invert: false,
          easeTime: 1000,
        },
        pullDownRefresh: {
          threshold: 0,
          stop: 50,
        },
        pullUpLoad: {
          threshold: -50,
        },
        observeDOM: true,
        freeScroll: true,
        scrollX: true,
        scrollY: true,
        observeImage: true,
        zoom: {
          start: 1,
          min: 0.5,
          max: 2,
        },
        autoEndDistance: 50,
      });
      this.scroll.on("touchEnd", throttle(this.imagesScroll, 500));
      this.scroll.on("mousewheelEnd", throttle(this.imagesScroll, 500));
      // this.scroll.on("pullingUp", this.pullingUpHandler);
      // this.scroll.on("pullingDown", this.pullingDownHandler);
      this.scroll.on("zoomEnd", ({ scale }) => {
        this.zoomScale = scale;
      });
      this.scroll.scroller.actionsHandler.hooks.on('click', throttle(() => this.setPanelSwitch(!this.panelSwitch), 100))

      // reload book from persist readList
      let bookIDs = Object.entries(this.readList).filter(([, { format }]) => format.toLowerCase() != 'exapi').map(([bookID]) => bookID)
      console.log(bookIDs)
      console.log(this.readList)
      let items;
      try {
        items = await this.$hanaWeb.bookDetail(bookIDs)
      } catch (e) {
        console.error(e)
      }
      let promises = []
      for (const [, { bookID, format, jsonData }] of Object.entries(this.readList)) {
        console.log(format, format.toLowerCase() == 'exapi')
        switch (format.toLowerCase()) {
          case 'cbz':
          case 'stream': {
            if (items?.[bookID]) {
              promises.push(this.addReadList(bookID, format, items[bookID], jsonData))
            }
            break
          }
          case 'exapi': {
            promises.push(this.addReadList(bookID, format, null, jsonData))
            break
          }
        }
      }
      return Promise.all(promises)
    },
    async loadThumb(pageIndex) {
      if (this?.currentBook?.item?.thumb == null) {
        return
      }
      if (this.currentBook.item.pageIndex == pageIndex) {
        return this.currentBook.item.thumb.items
      }
      return (await ehData.getDetail(this.currentBook.item, pageIndex))?.thumb?.items || []
    },
    setImageDivWidth(newWidth) {
      this.imageDivWidth = newWidth
      this.refresh() // it's necessary...
    }
  },
  computed: {
    showPanel() {
      return !this.currentBook || !this.$vuetify.breakpoint.mobile || this.panelSwitch
    },
    showPagination() {
      return !this.showPanel
    },
    paginationText() {
      if (this.currentBook) {
        return `${this.currentPage + 1}/${this.pages.length}`
      }
      return 'No page'
    },
    initialImageDivWidth() {
      return !this.$vuetify.breakpoint.mobile ? 50 : 100
    },
    supportFullScreen() {
      return this.$fullscreen.isEnabled
    },
    mdiMagnifyPlusOutline() {
      return mdiMagnifyPlusOutline
    },
    ...mapWritableState(StateStore, ['readList']),
    ...mapState(StateStore, ['pageIndex'])
  },
  created() { },
  mounted() {
    // it's hacked.....
    this.$root.$reader = this;
    this.init();
    console.log('lruBlob', this.lruBlob)
  },
  watch: {
    currentPage() {
      console.log("currentPage change", this.currentPage);
      if (this.pages.length == 0) {
        return;
      }
      // if (
      //   this.renderPages.length > 0
      // ) {
      //   if (this.currentPage > 0 && this.currentPage == this.renderPages[0].index) {
      //     console.log('go to first')
      //   }
      //   if (this.currentPage < this.pages.length - 1 && this.currentPage == this.renderPages[this.renderPages.length - 1].index) {
      //     console.log('go to last')
      //   }
      //   // skip page to get out of border, rerender new border
      //   // this.rerender();
      //   // this.trunPage(this.currentPage)
      // }
      console.log('selfChangePage', this.selfChangePage)
      // if (this.currentPage != this.selfChangePage) { // direct Jump Page
      //   this.scroll.scrollToElement(this.$refs["image-" + this.currentPage][0]);
      // }
      this.currentBook.pageManager?.pageChange(this.currentPage)
    },
    pageIndex(index) {
      switch (index) {
        // case 'view': {
        //   break;
        // }
        case 'read': {
          if (!this.currentBook) {
            break;
          }
          this.trunPage(this.currentPage)
          break;
        }
      }
    }
  },
};
</script>
<style lang="stylus" scoped>
.images-wrapper {
  background: #333;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  position: fixed;
  // @media screen and (max-width: 960px) {
  // height: calc(100vh - 144px);
  // }
  // @media screen and (min-width: 960px) {
  // height: calc(70vh)
  // }
  overflow: hidden;

  .image-wrapper {
    width: 100%;
    text-align: center;

    img {
      width: 100%;
    }
  }
}

.control-panel {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  bottom: 0;
}

.prep-image {
  height: calc(100vh - 144px)
}

@media screen and (max-width: 960px) {
  .image-div {
    width: 100%;
  }
}
@media screen and (min-width: 960px) {
  .image-div {
    width: 50%;
  }
}
.pagination {
  color: white;
  background: rgba(0,0,0,0.4);
  font-size: smaller;
  padding: .06rem .12rem;
  position: absolute;
  right: 0;
  bottom: 0;
}

</style>

import { LRUMap } from "lru_map_yxa2111"

// BlobEntry handle blobs lifetime
class BlobEntry {
    constructor(blobs, id, destroyCallback = null) {
        let size = 0
        this.url = []
        for (let b of blobs) {
            if (b instanceof Blob) {
                size += b.size
                // ref cleared
                const link = window.URL.createObjectURL(b)
                this.url.push(link)
            }
        }
        this.size = size
        this.id = id
        this.destroyCallback = destroyCallback
    }

    destroy() {
        for (let u of this.url) {
            window.URL.revokeObjectURL(u)
        }
        if (this.destroyCallback) {
            this.destroyCallback(this)
        }
        let ret = this.id
        this.url = null
        this.size = 0
        this.id = null
        return ret
    }
}

class LruBlob extends LRUMap {
    constructor(limit, del_cb) {
        del_cb = del_cb || (() => {})
        super(limit, null, e => e.size, (entry) => {
            let key = entry.destroy()
            console.log(`revoke key ${key}, current size ${this.size} limit ${this.limit}`)
            del_cb(key)
        })
    }
}

export {LruBlob, BlobEntry};
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import {MediaQueries} from 'vue-media-queries';
import axios from 'axios'
import VueAxios from 'vue-axios'
import {hana} from './components/web'
import Downloader from './components/Downloader';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import Fragment from 'vue-fragment'
import router from './router/index'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import fullscreen from 'vue-fullscreen'
import './registerServiceWorker'

const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);
Vue.use(VueAxios, axios)
Vue.use(hana)
Vue.use(Downloader)
Vue.use(Fragment.Plugin)
Vue.use(PiniaVuePlugin)
Vue.use(fullscreen);
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Vue.config.productionTip = false

new Vue({
  vuetify,
  mediaQueries,
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')

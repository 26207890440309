<template>
  <div>
    <!-- <book-carousel :books="randBooks" /> -->
    <div class="text-h5 py-5 px-4 text-center">
      <span v-if="shelfName?.length > 0">
        Shelf: {{ shelfName }}
      </span>
    </div>
    <book-view :books="books" :pagination="pagination"
      @update="({ order, nextPage }) => getBooks(nextPage, this.pageSize, order)">
      <template v-slot:new-button="slotProps">
        <v-btn value="rand" @click="slotProps.selected == 'rand' && getBooks(1, pageSize, 'rand')">
          <span class="hidden-sm-and-down">random</span>
          <v-icon small>
            mdi-shuffle
          </v-icon>
        </v-btn>
      </template>
    </book-view>
  </div>
</template>

<script>
import BookView from "@/components/BookView";
// import BookCarousel from "@/components/BookCarousel.vue";

export default {
  name: "Shelf",
  components: {
    BookView,
  },
  data: () => ({
    books: [],
    pageSize: 30,
    pagination: {
      page: 1,
      totalPage: 1,
      order: "new",
    },
  }),
  props: ['shelfID', 'shelfName'],
  methods: {
    async getBooks(page, pageSize, order) {
      const data = await this.setLoading(this.$hanaWeb.shelf(
        this.shelfID,
        page,
        pageSize,
        order
      ));
      this.books = data["item"];
      this.pagination = {
        page: data["page"],
        totalPage: data["totalpage"],
        order: order,
      };
    },
    async setLoading(promise) {
      this.$root?.$sysBar?.setLoading(true)
      try {
        return await promise;
      } finally {
        this.$root?.$sysBar?.setLoading(false)
      }
    },
  },
  created() {
    this.getBooks(1, this.pageSize, this.pagination.order);
  },
  watch: {
    $route() {
      this.getBooks(1, this.pageSize, this.pagination.order)
    }
  }
};
</script>
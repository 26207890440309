<template>
  <v-system-bar app :color="color" class="font-weight-bold" dark>
    <span class="pr-2 d-flex align-center">
      <v-progress-circular
        indeterminate
        size="16"
        width="3"
        color="white"
        v-if="isLoading"
      ></v-progress-circular>
    </span>
    <span @click="callback && callback()">
        {{msg}}
    </span>
    <v-spacer></v-spacer>

    <span class="pr-2" v-show="taskSize > 0">
      {{taskSize}} Tasks
    </span>
    <span class="pr-2 d-flex align-center">
      <v-progress-circular
        :value="progress"
        rotate="-90"
        size="16"
        v-show="progress < 100"
        color="white"
      ></v-progress-circular>
    </span>
    <span>
      {{fileSize(bytes)}}/s
    </span>
  </v-system-bar>
</template>

<script>
import {filesize} from "filesize";

export default {
  name: "HelloWorld",
  data: () => ({
    color: 'primary',
    msg: '',
    callback: null,
    taskSize: 0,
    bytes: 0,
    progress: 100,
    isLoading: false
  }),
  methods: {
    message(msg, callback, second) {
        this.msg = msg
        this.callback = callback
        setTimeout(()=>{
          this.msg = ''
        }, second*1000)
    },
    fileSize(number) {
      return filesize(number, {base: 2, standard: "jedec"})
    },
    setColor(c, second) {
        this.color = c
        setTimeout(()=>{
          this.color = 'primary'
        }, second*1000)
    },
    notifyDownload(tasks, bytes, progress) {
      this.taskSize = tasks
      this.bytes = bytes
      this.progress = progress
    },
    information(msg, seconds = 3, callback) {
      this.message(msg, callback, seconds)
      //this.setColor('blue lighten-3', seconds)
    },
    setLoading(show) {
      this.isLoading = show
    }
  },
  mounted() {
    this.$root.$sysBar = this;
  },
};
</script>
<template>
  <div class="d-flex justify-center flex-wrap pt-2">
    <div v-for="(item) in pages" :key="item.index" class="d-flex align-center flex-column" @click="onClick(item)"
      v-ripple>
      <v-img :src="item.img" :aspect-ratio="3 / 4" :width="large ? 200 : 110" contain v-if="!item.merged" />
      <div :style="item.img" class="ma-1" v-else></div>
      <span class="text-center">{{ item.index + 1 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pages', 'mode'],
  methods: {
    onClick(item) {
      this.$emit('click', item)
    }
  },
  computed: {
    large() {
      if (this.mode == null) {
        return !this.$vuetify.breakpoint.mobile
      }
      return this.mode == 'large'
    }
  }
}
</script>

<style></style>
import { defineStore } from 'pinia'

const dumpConfItem = [
    'currentUser',
    'accessToken',
    'baseURL',
    'readList',
    'searchPatterns',
    'baseEhURL',
    'maxCachedSize',
    'exCookies',
    'preloadPages',
    'darkMode'
]

export const StateStore = defineStore('stateStore', {
    state: () => ({
        pageIndex: 'view',
        currentUser: '',
        accessToken: '',
        baseURL: '',
        readList: {},
        readListChanged: 0, // modify readlist only DO NOT trigger persist....
        tagList: [],
        searchPatterns: [],
        baseEhURL: '',
        viewMode: 'hana',
        maxCachedSize: 1073741824, // 1024M
        exCookies: '',
        scrollToBack: false,
        preloadPages: 5,
        darkMode: false,
    }),
    actions: {
        addToReadList(bookID, bookItem) {
            console.log(bookID)
            this.readList[bookID] = bookItem
            this.readListChanged = Date.now()
        },
        removeFromReadList(bookID) {
            delete this.readList[bookID]
            this.readListChanged = Date.now()
        },
        existsReadList(bookID) {
            return this.readList[bookID] != null
        },
        logout() {
            this.currentUser = ''
            this.accessToken = ''
        },
        dumpConfig() {
            let obj = {}
            for (const item of dumpConfItem) {
                obj[item] = this[item]
            }
            return obj
        },
        restoreConfig(object) {
            console.log(object, this)
            for (const item of dumpConfItem) {
                if (object[item] != null) {
                    this[item] = object[item]
                }
            }
        }
    },
    persist: true
})
<template>
  <div class="d-flex justify-center">
    <v-card width="500">
      <v-card-text>
        <v-text-field
          v-model="username"
          required
          label="username"
          :rules="[(val) => (val || '').length > 0 || 'This field is required']"
        ></v-text-field>
        <v-text-field
          v-model="password"
          required
          label="password"
          :rules="[(val) => (val || '').length > 0 || 'This field is required']"
          type="password"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn large @click="login(username, password)">
          <v-icon> mdi-login-variant </v-icon>
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {StateStore} from '@/store/StateStore'
import { mapWritableState } from 'pinia'

export default {
  name: "Login",
  components: {},
  data: () => ({
    username: "",
    password: "",
  }),
  props: [],
  methods: {
    async login(username, password) {
      let resp
      try {
        resp = await this.$hanaWeb.login(username, password);
      } catch (e) {
        if (e.response && e.response.status == 401) {
          console.error("username password not match");
        } else if (e.response && e.response.status == 404) {
          console.error("username not found");
        } else {
          console.error(e);
        }
      }
      console.log(resp)
      this.accessToken = `Bearer ${resp.data.access_token}`
      this.currentUser = username
      if (await this.verify()) {
        console.log('login finish')
        this.$router.push('/home')
      }
    },
    async verify() {
      try {
        console.log(await this.$hanaWeb.me())
      } catch(e) {
        console.error(e)
        return false
      }
      return true
    }
  },
  computed: {
    ...mapWritableState(StateStore, ['currentUser', 'accessToken', 'viewMode'])
  },
  async mounted() {
    if (this.viewMode == 'exapi') {
      this.$router.push({name: 'ehsearch', params: { pattern: '' }})
      return
    }
    if (await this.verify()) {
      this.$router.push('/home')
      return
    }
    console.log('need login')
  },
};
</script>
<template>
  <v-navigation-drawer :value="this.pageIndex != 'read' && value"
    @input="onInput" app fixed :permanent="isExpand" :temporary="!isExpand"
    color="primary" dark class="elevation-8">
    <template v-slot:prepend>
      <v-list-item>
        <a href="https://twitter.com/Makoto_on_stage" class="text-decoration-none">
          <v-list-item-avatar>
            <img :src="require('@/assets/avatar.jpg')" />
          </v-list-item-avatar>
        </a>
      </v-list-item>
      <v-menu auto offset-y max-width="200">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-content>
              <v-list-item-title> {{ currentUser || 'Guest' }} </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-list dense nav>
          <v-list-item @click="onLogout">
            <v-list-item-icon>
              <v-icon dense>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <v-divider />
    <v-list nav class="font-weight-light" rounded>
      <v-list-item-group v-model="selected" mandatory>
        <router-link to="/" class="text-decoration-none">
          <v-list-item link color="white">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link to="/random" class="text-decoration-none">
          <v-list-item link color="white">
            <v-list-item-icon>
              <v-icon>mdi-shuffle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Random</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <v-list-group :value="true" color="white">
          <template v-slot:activator>
            <v-list-item-title>Shelf</v-list-item-title>
          </template>
          <div></div>
          <router-link :to="{ name: 'shelf', params: { shelfID: shelf.id, shelfName: shelf.name } }"
            class="text-decoration-none" v-for="shelf of shelfList" :key="shelf.id">
            <v-list-item link color="white">
              <v-list-item-icon>
                <v-icon v-if="!shelf.tagshelf">mdi-book</v-icon>
                <v-icon v-else>mdi-notebook</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ shelf.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-group>
        <v-list-group :value="true" color="white">
          <template v-slot:activator>
            <v-list-item-title>Search</v-list-item-title>
          </template>
          <div></div>
          <!-- <router-link :to="{ name: 'shelf', params: { shelfID: shelf.id, shelfName: shelf.name } }"
            class="text-decoration-none" v-for="shelf of shelfList" :key="shelf.id"> -->
            <v-list-item link v-for="(search, index) in searchPatterns" :key="search[0] + index" @click="toSearch(search[1])" color="white">
              <!-- <v-list-item-icon>
                <v-icon v-if="!shelf.tagshelf">mdi-book</v-icon>
                <v-icon v-else>mdi-notebook</v-icon>
              </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-title>{{ search[0] }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="searchPatterns.splice(index, 1)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          <!-- </router-link> -->
        </v-list-group>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>

import { mapState, mapActions, mapWritableState } from "pinia";
import { StateStore } from "@/store/StateStore";

export default {
  name: "Drawer",
  data: () => ({
    shelfList: [],
    selected: 0,
  }),
  props: ['value'],
  methods: {
    ...mapActions(StateStore, ['logout']),
    onInput(e) {
      this.$emit('input', e)
    },
    async getShelfList() {
      const data = await this.$hanaWeb.shelfList()
      this.shelfList = data
    },
    onLogout() {
      this.logout()
      this.shelfList = []
      this.$router.push('/login')
    },
    toSearch(text) {
      this.$root.$tagList.onSearch(text)
    }
  },
  computed: {
    ...mapState(StateStore, ['currentUser', 'pageIndex']),
    ...mapWritableState(StateStore, ['searchPatterns']),
    isExpand() {
      return this.pageIndex != 'read' && this.$mq.resize && this.$mq.above(960)
    },
  },
  watch: {
    currentUser() {
      if (this.currentUser.length > 0) {
        this.getShelfList()
      }
    }
  },
  mounted() {
    this.getShelfList()
  },
};
</script>
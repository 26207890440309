<template>
  <div>
    <v-menu offset-y v-model="showMenu">
      <template v-slot:activator="{ on }" >
        <v-text-field
          :loading="loading"
          :value="text"
          @input="onInput"
          @click="loadData"
          v-on="on"
          filled
          placeholder="use Tag to Search"
          dense
          clearable
          append-icon="mdi-magnify"
          @click:append="$emit('search', text)"
          :rules="[() => isValidText() || '']"
          @keydown="(e)=>{ if(!showMenu && e && e.keyCode == 13) $emit('search', text) }"
        >
        
        </v-text-field>
      </template>

      <v-list v-show="items.length > 0">
        <v-list-item v-for="(item, index) in items" :key="index" @click="onClick(item)">
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { get_parser, Tree, Token } from "@/components/TagSearch/TagParser.js";
import Resolver from "@/components/TagSearch/Resolver.js";
let resolver = new Resolver([])

export default {
  data: () => ({
    parser: null,
    text: "",
    loading: false,
    search: null,
    showMenu: false,
    lastOne: null,
    items: [],
    ast: null,
  }),
  mounted() {
    try {
      this.parser = get_parser({
        keep_all_tokens: true,
        parser: "lalr",
      })
    } catch(e) {
      console.error('lark parser init failed', e)
    }
  },
  watch: {},
  methods: {
    async loadData() {
      if (resolver.length() == 0) {
        this.loading = true
        let beginTs;
        beginTs = Date.now()
        try {
          const entries = await this.$hanaWeb.getTags()
          if (entries) {
            const data = new Map(Object.entries(entries))
            resolver = new Resolver(data)
          }
        } finally {
          this.loading = false
        }
        let endTs = Date.now()
        console.log('load tag cost', endTs - beginTs)
      }
    },
    isValidText() {
      if (!this.text)
        return true
      try {
        this.parser.parse(this.text)
        return true
      } catch(e) {
        return false
      }
    },
    onInput(text) {
      this.text = text;
      if (text == '') {
        this.items = []
      }
      try {
        this.ast = this.parser.parse(text);
      } catch (e) {
        return;
      }
      this.lastOne = this.findLastOne(this.ast);
      let lastStr = this.treeToStr(this.lastOne);
      if (lastStr.endsWith('"')) {
        lastStr = lastStr.slice(0, lastStr.length - 1);
      }
      const maxSearch = 5
      let result = resolver.find(lastStr);
      const menuItem = result.flatMap(item=>item[1].map(entry=>entry[1]))
      const itemSet = new Set()
      const resultItem = []
      for (let i = 0; i < Math.min(menuItem.length, maxSearch); ++i) {
        if (itemSet.has(menuItem[i]))
          continue
        resultItem.push(menuItem[i])
        itemSet.add(menuItem[i])
      }
      this.items = [...resultItem]
      if (this.items.length > 0) {
        this.showMenu = true
      }
    },
    onClick(content) {
      let entry;
      try {
        entry = this.parser.parse(content)
      } catch(e) {
        return
      }
      if (!entry) {
        return
      }
      this.lastOne.data = entry.data
      this.lastOne.children = entry.children
      this.text = this.treeToStr(this.ast)
      this.showMenu = false
    },
    findLastOne(data) {
      if (data instanceof Tree) {
        const children = data.children.filter((v) => v instanceof Tree);
        switch (data.data) {
          case "start":
            return this.findLastOne(children[0]);
          case "sentence":
            return this.findLastOne(children[children.length - 1]);
          case "ones":
            return this.findLastOne(children[children.length - 1]);
          case "tag":
          case "query":
            return data;
        }
      }
    },
    treeToStr(u) {
      if (u instanceof Tree) {
        switch (u.data) {
          case "not":
            return "-";
          case "option":
            return "?";
          case "tag":
            return (
              this.treeToStr(u.children[0]) +
              ":" +
              (u.children.length > 1
                ? '"' + this.treeToStr(u.children[1]) + '"'
                : "")
            );
          case "and":
            return "&";
          case "or":
            return "|";
          case "sentence":
            if (u.children.length > 2) {
              let children = [];
              for (let i = 0; i < u.children.length; i += 1) {
                let res = this.treeToStr(u.children[i]);
                if (
                  u.children[i] instanceof Tree &&
                  u.children[i].data == "sentence" &&
                  u.children[i].children[0].data != "ones"
                ) {
                  res = "(" + res + ")";
                }
                children.push(res);
              }
              return children.join("");
            } else {
              return u.children.map((elem) => this.treeToStr(elem)).join("");
            }
          default:
            return u.children.map((elem) => this.treeToStr(elem)).join("");
        }
      }
      if (u instanceof Token) {
        return u.value;
      }
    },
  },
  computed: {
  },
};
</script>

<style>
</style>
import {hanaWeb} from '@/components/web.js'
import { BlobEntry } from '@/components/LruBlob'
import storage from '@/pages/Reader/CachedStorage'

class ChunkManager {
    constructor(bookID, chunks, pages, downloader, lru, reader) {
        this.chunks = []
        for (const chunk of chunks) {
            this.chunks.push({
                start: chunk[0],
                end: chunk[1],
                format: chunk[2]
            })
        }
        this.pages = pages
        this.downloader = downloader
        this.lru = lru
        this.bookID = bookID
        this.reader = reader
        this.storage = storage()
    }

    toJson() {
        let chunks = []
        for (const {start, end, format} of this.chunks) {
            chunks.push({
            range: [start + 1, end + 1],
            ext: format
            })
        }
        return {pages: chunks}
    }

    static fromJson(data, bookID, downloader, lruBlob, reader) {
        let chunks = [];
        let total = 0;
        for (const chunk of data["pages"]) {
          chunks.push([
            chunk["range"][0] - 1,
            chunk["range"][1] - 1,
            chunk["ext"],
          ]);
          total = Math.max(total, chunk["range"][1]);
        }
        let newPages = [];
        for (let i = 0; i < total; ++i) {
          newPages.push({
            name: "image-" + i, //useless
            url: "",
            index: i,
            ctx: null // we must set it first to be reactive
          });
        }
        return new ChunkManager(
          bookID,
          chunks,
          newPages,
          downloader,
          lruBlob,
          reader
        );
    }

    genKey(pageIndex) {
        return `${this.bookID}-${pageIndex}`
    }

    parseKey(key) {
        return key.split('-')
    }

    evictChunk(key) {
        const [bookID, pageIndex] = this.parseKey(key)
        if (bookID != this.bookID)
            return
        const chunk = this.findChunk(pageIndex)
        if (!chunk)
            return
        for (let i = chunk.start; i <= chunk.end; ++i) {
            this.pages[i].url = ''
        }
    }

    findChunk(pageIndex) {
        for (const chunk of this.chunks) {
            if (chunk.start <= pageIndex && pageIndex <= chunk.end) {
                return chunk
            }
        }
        return
    }

    pageLoaded(pageIndex) {
        const chunk = this.findChunk(pageIndex)
        if (!chunk)
            return false
        return this.lru.has(this.genKey(chunk.start))
    }

    genChunkKey(bookID, chunkFormat) {
        return `${bookID}-${chunkFormat}`
    }

    async downloadChunk(bookID, format, range) {
        if (this.downloader.isDownloading(hanaWeb.bookDataURL(bookID, format))) {
            return
        }
        const ctx = this.downloader.download(hanaWeb.bookDataURL(bookID, format))
        for (let i = range[0]; i < Math.min(range[1] + 1, this.pages.length); ++i) {
            this.pages[i].ctx = ctx
        }
        return ctx.promise
    }

    async loadPage(pageIndex) {
        if (this.pageLoaded(pageIndex))
            return
        const chunk = this.findChunk(pageIndex)
        if (!chunk)
            return
        const chunkKey = this.genChunkKey(this.bookID, chunk.format)
        let file = await this.storage.getImageOrLoad(chunkKey, async () => this.downloadChunk(this.bookID, chunk.format, [chunk.start, chunk.end]))
        if (!file) {
            return
        }
        // just use default sort in zip, calibre sort it before...
        const entries = await this.reader.readPicInZip(file, false)
        console.log(entries)
        const blobs = []
        for (let entry of entries) {
            blobs.push(entry.blob)
            delete entry.blob
        }
        const key = this.genKey(chunk.start)
        const blobEntry = new BlobEntry(blobs, key, (entry) => this.evictChunk(entry.id))
        this.lru.set(key, blobEntry)
        for (let i = 0; i < blobEntry.url.length; ++i) {
            this.pages[chunk.start + i].url = blobEntry.url[i]
        }
    }

    pageChange(currentPage) {
      const chunk = this.findChunk(currentPage);
      if (!this.pageLoaded(currentPage)) {
        this.reader.$root.$sysBar.information(
          `load page ${chunk.start + 1}-${chunk.end + 1}`
        );
        this.loadPage(currentPage);
      }
      const chunkSize = chunk.end - chunk.start + 1;
      const loadedPage = currentPage - chunk.start + 1;
      const loadFactor = chunkSize <= 6 ? 0.3 : 0.5;
      const nextChunk = this.findChunk(chunk.end + 1);
      if (
        nextChunk &&
        !this.pageLoaded(chunk.end + 1) &&
        loadedPage >= chunkSize * loadFactor
      ) {
        this.reader.$root.$sysBar.information(
          `load next page ${nextChunk.start + 1}-${nextChunk.end + 1}`
        );
        this.loadPage(chunk.end + 1);
      }
    }

    destroy() {
        for (const chunk of this.chunks) {
            this.lru.delete(this.genKey(chunk.start))
        }
    }
}

export default ChunkManager
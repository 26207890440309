<template>
  <v-carousel
    delimiter-icon="mdi-minus"
    cycle
    interval="3000"
    hide-delimiter-background
    height="600"
  >
  <template v-slot:prev="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        x-large
      ><v-icon>mdi-arrow-left</v-icon></v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        x-large
      ><v-icon>mdi-arrow-right</v-icon></v-btn>
    </template>
  <book-carousel-item v-for="(book, i) in books" :key="i" :book="book"/>
  </v-carousel>
</template>

<script>
import BookCarouselItem from './BookCarouselItem';
export default {
  name: "BookCarousel",
  components: {
    BookCarouselItem
  },
  data: () => ({
    drawer: null,
    showDetail: {}
  }),
  props: ["books"],
};
</script>
<template>
    <v-carousel-item eager>
      <v-img
        :src="$hanaWeb.bookCoverURL(book.id)"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.4)"
        position="top"
        height="600"
        @click="showDetail = true"
        eager
      >
        <v-card-title v-text="book.shorten_title"></v-card-title>
      </v-img>
      <book-detail v-model="showDetail" :book="book"/>
    </v-carousel-item>
</template>

<script>
import BookDetail from "./BookDetail";
export default {
  name: "BookCarouselItem",
  components: {
    BookDetail
  },
  data: () => ({
    drawer: null,
    showDetail: false
  }),
  props: ["book"],
};
</script>
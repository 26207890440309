<template>
  <div>
    <div class="text-h5 py-5 px-4 text-center">
      <span v-if="query?.length > 0">
        Result: {{ query }}
      </span>
    </div>
    <book-view :books="books" :pagination="pagination" @update="({ order, nextPage }) => getBooks(nextPage, this.pageSize, order)
      ">
    </book-view>
  </div>
</template>

<script>
import BookView from "@/components/BookView";
// import BookCarousel from "@/components/BookCarousel.vue";

export default {
  name: "Search",
  components: {
    BookView,
  },
  data: () => ({
    books: [],
    pageSize: 30,
    pagination: {
      page: 1,
      totalPage: 1,
      order: "new",
    },
  }),
  props: ["query"],
  methods: {
    async getBooks(page, pageSize, order) {
      const data = await this.setLoading(this.$hanaWeb.search(
        this.query,
        page,
        pageSize,
        order
      ));
      this.books = data["item"];
      this.pagination = {
        page: data["page"],
        totalPage: data["totalpage"],
        order: order,
      };
    },
    async setLoading(promise) {
      this.$root?.$sysBar?.setLoading(true)
      try {
        return await promise;
      } finally {
        this.$root?.$sysBar?.setLoading(false)
      }
    },
  },
  created() {
    console.log(this.query)
    console.log(this.$router)
    this.getBooks(1, this.pageSize, this.pagination.order);
  },
  watch: {
    $route() {
      this.getBooks(1, this.pageSize, this.pagination.order);
    },
  },
};
</script>
<template>
  <div>
    <v-card max-width="300" hover class="ma-auto">
      <a :href="book.url" target="_blank" style="text-decoration: none">
        <v-img
          :src="book.cover || $hanaWeb.bookCoverURL(book.id)"
          :height="$vuetify.breakpoint.mobile ? 150 : 300"
          position="top"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.4)"
        >
          <!-- <div class="fill-height bottom-gradient"></div> -->
          <!-- <p class="lang-mark">
          <span class="lang-mark-text">{{ book.langmark }}</span>
        </p> -->
          <v-card-title v-text="book.shorten_title"></v-card-title>
        </v-img>
      </a>
      <v-card-actions>
        <v-chip v-if="book.mark.length > 0" color="red accent-1">{{
          book.mark
        }}</v-chip>
        <!-- <v-chip-group column>
            <v-chip v-for="(author, index) in book.author" :key="index">
              {{ author.name }}
            </v-chip>
          </v-chip-group> -->
        <v-spacer></v-spacer>
        <v-btn icon @click="showDetail = !showDetail">
          <v-icon>mdi-view-headline</v-icon>
        </v-btn>
        <v-btn icon @click="onAddReadList"
        :disabled="defaultSelected == null || inReadList">
          <v-icon>mdi-book-plus-multiple-outline</v-icon>
        </v-btn>
      </v-card-actions>
      <!-- <v-card-actions>
<v-spacer></v-spacer>
<v-btn color="primary" text @click="dialog = false"> I accept </v-btn>
</v-card-actions> -->
    </v-card>
    <book-detail v-model="showDetail" :book="book" :loadDetail="loadDetail"/>
  </div>
</template>

<script>
import BookDetail from "./BookDetail";
import { StateStore } from "@/store/StateStore";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "BookCard",
  components: {
    BookDetail,
  },
  data: () => ({
    drawer: null,
    showDetail: false,
    inReadList: false,
  }),
  props: ["book", "loadDetail"],
  computed: {
    ...mapWritableState(StateStore, ["readListChanged"]),
    selectableFormat() {
      const support = new Set(["stream", "cbz", 'exapi']);
      let ret = [];
      for (let data of this.book.data) {
        if (support.has(data.format.toLowerCase())) {
          ret.push(data);
        }
      }
      return ret;
    },
    defaultSelected() {
      const selectable = this.selectableFormat;
      if (selectable.length == 0) {
        return null;
      }
      for (const data of selectable) {
        const format = data.format.toLowerCase();
        if (format == "stream") {
          return "stream";
        }
      }
      return selectable[0].format;
    },
  },
  methods: {
    ...mapActions(StateStore, [
      "addToReadList",
      "removeFromReadList",
      "existsReadList",
    ]),
    onAddReadList() {
      this.$root.$reader.loadBook(
        this.book.id,
        this.defaultSelected,
        this.book
      );
    },
  },
  watch: {
    readListChanged() {
      this.inReadList = this.existsReadList(this.book.id);
    },
  },
};
</script>
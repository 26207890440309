<template>
  <v-list-item
  >
    <v-img
      :src="record.item.cover || $hanaWeb.bookCoverURL(bookID)"
      height="100"
      max-width="100"
      class="mr-4"
      contain
    />
    <v-list-item-content @click="$emit(`switchBook`, {bookID, format: record.format, item: record.item})">
      <v-list-item-title>{{ record.item.title }}</v-list-item-title>
      <v-list-item-subtitle>
        {{ record.item.mark }} ID: {{ bookID }} Type: {{ record.format }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Progress: {{ record.currentPage + 1 }} / {{ record.pages.length }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon large @click="$emit(`closeBook`, record)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn
        icon
        large
        @click="showDetail=true"
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </v-list-item-action>
    <book-detail v-model="showDetail" :book="record.item"/>
  </v-list-item>
</template>

<script>
import BookDetail from '@/components/BookDetail'
export default {
    props: ['bookID', 'record'],
    mounted() {
        console.log(this.bookID, this.record)
    },
    components: {
      BookDetail
    },
    data: ()=>({
      showDetail: false
    })
};
</script>

<style>
</style>
<template>
  <v-app id="inspire">
    <system-bar />

    <v-app-bar app v-show="innerPageIndex == 'view'" color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-toolbar-title v-show="!$vuetify.breakpoint.mobile || !searchExpand" v-bind="attrs" v-on="on">
            <template v-if="viewMode == 'hana'">
              Hana Viewer
            </template>
            <template v-if="viewMode == 'exapi'">
              EhViewer
            </template>
          </v-toolbar-title>
        </template>
        <v-list>
          <v-list-item-group mandatory v-model="viewMode">
            <v-list-item value="hana">
              <v-list-item-title>Hana Viewer</v-list-item-title>
            </v-list-item>
            <v-list-item value="exapi">
              <v-list-item-title>EhViewer</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn icon v-show="$vuetify.breakpoint.mobile && searchExpand" @click="searchExpand = false">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <template v-if="viewMode == 'hana'">
        <tag-search v-show="!$vuetify.breakpoint.mobile || searchExpand" class="pt-5 search-bar px-md-6"
          @search="onSearch" />
      </template>
      <template v-if="viewMode == 'exapi'">
        <eh-search v-show="!$vuetify.breakpoint.mobile || searchExpand" class="pt-5 search-bar px-md-6"
          @search="onSearch" />
      </template>
      <v-spacer v-show="!$vuetify.breakpoint.mobile || !searchExpand" />
      <router-link to="/" class="text-decoration-none">
        <v-btn icon large v-show="!$vuetify.breakpoint.mobile || !searchExpand">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </router-link>
      <v-btn icon large v-show="$vuetify.breakpoint.mobile && !searchExpand" @click="searchExpand = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <router-link to="/setting" class="text-decoration-none">
        <v-btn icon large v-show="!$vuetify.breakpoint.mobile || !searchExpand">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </router-link>
    </v-app-bar>

    <v-main class="background">
      <drawer v-model="drawer" />
      <transition>
        <v-container :class="$vuetify.breakpoint.mobile ? 'pa-0' : ''">
          <router-view v-show="innerPageIndex == 'view'"></router-view>
        </v-container>
        </transition>
      <reader v-show="innerPageIndex == 'read'" />
    </v-main>
    <snack-bar />
    <div class="d-flex list-btn flex-column align-start">
      <div class="px-4 pb-2">
        <v-btn fab @click="showTagList = !showTagList" v-show="showTagListBtn">
          <v-badge v-show="tagList.length > 0" :content="tagList.length" color="pink">
            <v-icon>mdi-tag-multiple-outline</v-icon>
          </v-badge>
        </v-btn>
      </div>
      <div class="pa-4 pt-2">
        <v-btn fab @click="onBackToTop" v-show="innerScrollToBack">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </div>
    <tag-list :value="showTagList && tagList.length > 0" @input="v => { showTagList = v }" />
    <v-bottom-navigation app :value="innerPageIndex" @change="switchPage">
      <v-btn value="view">
        <span>View</span>
        <v-icon>mdi-view-module</v-icon>
      </v-btn>
      <v-btn value="read" @click="onReadNavgClick">
        <span>Read</span>
        <v-badge v-if="readListCount > 0" :content="readListCount" color="pink">
          <v-icon>mdi-book-multiple</v-icon>
        </v-badge>
        <v-icon v-else>mdi-book-multiple</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
// import Viewer from '@/pages/Viewer/Viewer.vue'
import Reader from "@/pages/Reader/Reader.vue";
//import Viewer from './components/Viewer/Viewer.vue'
import { StateStore } from "@/store/StateStore";
import { mapWritableState, mapState, mapActions } from "pinia";
import SystemBar from '@/components/SystemBar.vue'
import TagSearch from '@/components/TagSearch/TagSearch'
import TagList from '@/components/TagList'
// import EhViewer from './pages/ehViewer/ehViewer.vue';
import ehSearch from '@/components/ehSearch'
import Drawer from '@/components/Drawer'
import SnackBar from "@/components/SnackBar.vue";

export default {
  name: "App",

  components: {
    // Viewer,
    Reader,
    SystemBar,
    TagSearch,
    TagList,
    // EhViewer,
    ehSearch,
    Drawer,
    SnackBar,
  },

  data: () => ({
    drawer: null,
    innerPageIndex: "view",
    searchExpand: false,
    readListCount: 0,
    showTagList: false,
    innerScrollToBack: false,
  }),
  methods: {
    ...mapActions(StateStore, ['logout']),
    switchPage(pageIndex) {
      this.pageIndex = pageIndex;
    },
    onSearch(text) {
      if (this.viewMode == 'hana') {
        this.$router.push({ path: '/search/' + text })
      } else if (this.viewMode == 'exapi') {
        this.$router.push({ name: 'ehsearch', params: { pattern: text } })
      }
    },
    onBackToTop() {
      //const ref = this.$refs['main']
      //todo: use scrollIntoView to scroll to spec elem https://developer.mozilla.org/zh-CN/docs/Web/API/Element/scrollIntoView
      //window.scrollBy(0, -window.pageYOffset)
      document.body.scrollIntoView({
        behavior: "smooth",
      });
    },
    onReadNavgClick() {
      this.$root.$reader.setPanelSwitch(true)
    },
  },
  computed: {
    ...mapWritableState(StateStore, ["pageIndex", 'currentUser', 'readList', 'readListChanged', 'viewMode']),
    ...mapState(StateStore, ['scrollToBack', 'tagList', 'darkMode']),
    showTagListBtn() {
      return this.tagList.length > 0 && this.innerPageIndex != 'read' && !this.showTagList
    },
  },
  watch: {
    pageIndex() {
      this.innerPageIndex = this.pageIndex;
    },
    readListChanged() {
      this.readListCount = Object.keys(this.readList).length
    },
    scrollToBack() {
      this.innerScrollToBack = this.scrollToBack
    }
  },
  mounted() {
    this.pageIndex = 'view'
    this.$hanaWeb.setAuthCallback(() => {
      this.logout()
      this.$root?.$sysBar?.message('Authorization failed, login required', () => {
        this.$router.push('/login')
      }, 10)
      this.$root?.$sysBar?.setColor('red', 10)
    })
    this.$downloader.setNotifycallback(this.$root?.$sysBar?.notifyDownload)
    this.readListCount = Object.keys(this.readList).length
    this.$vuetify.theme.dark = this.darkMode
    console.log(this.$root)
  }
};
</script>

<style scoped>
@media screen and (max-width: 960px) {
  .search-bar {
    width: 100%;
  }
}

@media screen and (min-width: 960px) {
  .search-bar {
    /* width: 40%; */
  }
}

.list-btn {
  position: fixed;
  bottom: 20%;
  right: 0%;
  z-index: 9999;
}
</style>
import { LRUMap } from "lru_map_yxa2111"
import { db } from '@/components/db.js'
import { StateStore } from "@/store/StateStore";

// simliar with BlobEntry
class CachedImageItem {
  constructor(id, size) {
    this.id = id
    this.size = size
  }

  async destroy() {
    try {
      await db.transaction('rw', db.CachedImage, async () => {
        await db.CachedImage.delete(this.id)
      })
    } catch(e) {
      console.error('remove CachedImage fail', e)
    }
  }

  async getBlob() {
    return (await db.CachedImage.where('id').equals(this.id).first())?.blob
  }
}

// key: imageId, value: CachedImage
class LruImage extends LRUMap {
  constructor(limit) {
    super(limit, null, item => item.size, (entry) => {
      entry.destroy()
      console.log(`revoke CachedImage ${entry.id}, current size ${this.size} limit ${this.limit}`)
    })
  }
}

class CachedStorage {
  constructor(maxCachedSize) {
    this.maxCachedSize = maxCachedSize
    this.lru = new LruImage(maxCachedSize)
    this.loaded = false
  }

  async loadAll(force = false) {
    if (this.loaded && !force) {
      return
    }
    this.loaded = true
    await db.transaction('rw!', db.CachedImage, async () => {
      await db.CachedImage.orderBy('timestamp').each(item => {
        console.log('set',item.id,'timestamp',item.timestamp)
        this.setImageByItem(item.id, item.size)
      })
    })
    console.log('cachedImage lru load', this.lru.size)
  }

  async getImageOrLoad(id, loadCallback) {
    let result = await this.lru.get(id)?.getBlob()
    if (result) {
      console.log('load cachedImage', id, 'from cache')
      return result
    }
    result = this.setImage(id, await loadCallback(id))
    if (result) {
      console.log('load cachedImage', id, 'from web')
    }
    return result
  }

  setImage(id, blob) {
    if (!blob || !(blob instanceof Blob)) {
      return blob
    }
    if (this.lru.has(id)) {
      return blob
    }
    db.CachedImage.put({id, blob, size: blob.size, timestamp: Date.now()})
    this.lru.set(id, new CachedImageItem(id, blob.size))
    return blob
  }

  setImageByItem(id, size) {
    if (this.lru.has(id)) {
      return
    }
    this.lru.set(id, new CachedImageItem(id, size))
  }

  async getWorkMeta(workid, index) {
    return (await db.WorkReadMeta.get({workid, index}))?.value
  }

  async setWorkMeta(workid, index, value) {
    await db.WorkReadMeta.put({workid, index, value})
  }

  async setMaxCacheSize(newSize) {
    this.maxCachedSize = newSize
    this.lru = new LruImage(this.maxCachedSize)
    await this.loadAll()
  }
}

let _;
let storage = () => {
  if (_) {
    return _
  }
  _ = new CachedStorage(StateStore().maxCachedSize)
  _.loadAll()
  return _
}

export default storage
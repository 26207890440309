import Vue from 'vue';
import VueRouter from 'vue-router';
import Viewer from '@/pages/Viewer/Viewer'
import Reader from '@/pages/Reader/Reader'
import RandomBook from '@/pages/Viewer/RandomBook'
import Shelf from '@/pages/Viewer/Shelf'
import Login from '@/pages/Login'
import Setting from '@/pages/Setting'
import Search from '@/pages/Viewer/Search'
import ehViewer from '@/pages/ehViewer/ehViewer'

Vue.use(VueRouter)

const routes = [
    {path: '/', component: Login, name: 'index'},
    {path: '/login', component: Login, name: 'login'},
    {path: '/home', component: Viewer, name: 'home'},
    {path: '/random', component: RandomBook, name: 'randomBook'},
    {path: '/shelf/:shelfID/:shelfName', component: Shelf, props: true, name: 'shelf'},
    {path: '/reader', component: Reader, name: 'reader'},
    {path: '/setting', component: Setting, name: 'setting'},
    {path: '/search/:query', component: Search, props: true},
    {path: '/ehsearch/:pattern', component: ehViewer, props: true, name: 'ehsearch'},
    // {path: '/loading', component: LoadingComponent},
    // {path: '*', component: NotFoundComponent}
];
const router = new VueRouter({
    routes
});
export default router;

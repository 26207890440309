<template>
  <div>
    <!-- <book-carousel :books="randBooks" /> -->
    <div class="text-h5 py-5 px-4 text-center">
      <span v-if="pattern?.length > 0">
        Result: {{ pattern }}
      </span>
    </div>
    <book-view :books="books" :loadDetail="getDetail">
      <template v-slot:button-group>
        <div></div>
      </template>
      <template v-slot:pagination>
        <div class="d-flex justify-center py-8" v-show="books.length > 1">
          <v-btn-toggle borderless color="primary" tile>
            <v-btn :disabled="loading || current.prevGid == ''" @click="getBooks(pattern, current.prevGid, false)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn :disabled="loading || current.prevGid == ''" @click="getBooks(pattern)">
              <v-icon>mdi-page-first</v-icon>
            </v-btn>
            <v-btn :disabled="loading || current.prevGid == ''" @click="getBooks(pattern, current.nextGid, true)">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
    </book-view>
  </div>
</template>

<script>
import BookView from "@/components/BookView";
import ehData from "@/pages/ehViewer/ehData.js"

export default {
  name: "ehViewer",
  components: {
    BookView,
  },
  data: () => ({
    books: [],
    current: {
      nextGid: '',
      prevGid: ''
    },
    loading: false
  }),
  props: ['pattern'],
  methods: {
    exportTitle(title) {
      const prefix = new RegExp("^(?:(?:\\([^\\)]*\\))|(?:\\[[^\\]]*\\])|(?:\\{[^\\}]*\\})|(?:~[^~]*~)|\\s+)*", 'i')
      const suffix = new RegExp("(?:\\s+ch.[\\s\\d-]+)?(?:(?:\\([^\\)]*\\))|(?:\\[[^\\]]*\\])|(?:\\{[^\\}]*\\})|(?:~[^~]*~)|\\s+)*$", 'i')
      title = title.replace(prefix, '').replace(suffix, '')
      const result = title.split('|')
      if (result.length >= 2) {
        return result[result.length - 1]
      }
      return title
    },
    async getBooks(pattern, nextGid, isNext) {
      this.$root?.$sysBar?.setLoading(true)
      this.loading = true
      try {
        this.books = await ehData.getBooks(pattern, nextGid, isNext)
      } finally {
        this.$root?.$sysBar?.setLoading(false)
        this.loading = false
      }
      if (this.books?.length > 0) {
        this.current.nextGid = this.books[this.books.length - 1].gid
        this.current.prevGid = this.books[0].gid
      }
    },
    async getDetail(book) { return ehData.getDetail(book) }
  },
  created() {
    this.getBooks(this.pattern)
  },
  watch: {
    pattern(p) {
      console.log(p)
      this.getBooks(p)
    }
  }
};
</script>
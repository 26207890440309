
// "key:val" -> ["key", "val"]
function parseTag(s) {
    s = s.split(':')
    if (s.length > 0) {
        let first = s[0];
        let second = s.slice(1).join(':')
        if (first == 'size') {
            first = 'size_'
        }
        return [first, second]
    }
    return []
}

// ["key", "val1 val2"] -> "key:"val1 val2""
function genTag(t) {
    if (t[0] == 'size_') {
        t[0] = 'size'
    }
    if (t[1] == '') {
        return t[0]
    }
    return `${t[0]}:"${t[1]}"`
    // if (t[1].includes(' ')) {
    //   t[1] = '"' + t[1] + '"'
    // }
    // return t[0] + ":" + t[1]
}

// function parseTags(val) {
//     let precidates = [];
//     let last = '';
//     function f() {
//         precidates.push(parseTag(last))
//         last = ''
//     }
//     for (let i = 0; i < val.length;) {
//         if (val[i] == '"') {
//             let end = i + 1;
//             while (end < val.length && val[end] != '"') {
//                 end++;
//             }
//             if (end == val.length) {
//                 return precidates;
//             }
//             last += val.substr(i + 1, end - i - 1);
//             i = end + 1;
//         } else if (val[i] == ' ') {
//             f()
//             i++
//         } else {
//             last += val[i]
//             i++
//         }
//     }
//     f()
//     return precidates
// }

// Trie.js - super simple JS implementation
// https://en.wikipedia.org/wiki/Trie

// -----------------------------------------

// we start with the TrieNode
function TrieNode(key, value = null) {
    // the "key" value will be the character in sequence
    this.key = key;

    // we keep a reference to parent
    this.parent = null;

    // we have hash of children
    this.children = {};

    // check to see if the node is at the end
    this.end = false;

    this.value = value

}

// iterates through the parents to get the word.
// time complexity: O(k), k = word length
TrieNode.prototype.getWord = function () {
    var output = [];
    var node = this;

    while (node !== null) {
        output.unshift(node.key);
        node = node.parent;
    }

    return output.join('');
};

// -----------------------------------------

// we implement Trie with just a simple root with null value.
function Trie() {
    this.root = new TrieNode(null);
    this.length = 0
}

// inserts a word into the trie.
// time complexity: O(k), k = word length
Trie.prototype.insert = function (word, value = null) {
    var node = this.root; // we start at the root 😬

    // for every character in the word
    for (var i = 0; i < word.length; i++) {
        // check to see if character node exists in children.
        if (!node.children[word[i]]) {
            // if it doesn't exist, we then create it.
            this.length += 1
            node.children[word[i]] = new TrieNode(word[i], value);

            // we also assign the parent to the child node.
            node.children[word[i]].parent = node;
        }

        // proceed to the next depth in the trie.
        node = node.children[word[i]];

        // finally, we check to see if it's the last word.
        if (i == word.length - 1) {
            // if it is, we set the end flag to true.
            node.end = true;
        }
    }
};

// check if it contains a whole word.
// time complexity: O(k), k = word length
Trie.prototype.contains = function (word) {
    var node = this.root;

    // for every character in the word
    for (var i = 0; i < word.length; i++) {
        // check to see if character node exists in children.
        if (node.children[word[i]]) {
            // if it exists, proceed to the next depth of the trie.
            node = node.children[word[i]];
        } else {
            // doesn't exist, return false since it's not a valid word.
            return false;
        }
    }

    // we finished going through all the words, but is it a whole word?
    return node.end;
};

// returns every word with given prefix
// time complexity: O(p + n), p = prefix length, n = number of child paths
Trie.prototype.find = function (prefix, limits) {
    var node = this.root;
    var output = [];

    // for every character in the prefix
    for (var i = 0; i < prefix.length; i++) {
        // make sure prefix actually has words
        if (node.children[prefix[i]]) {
            node = node.children[prefix[i]];
        } else {
            // there's none. just return it.
            return output;
        }
    }

    // recursively find all words in the node
    findAllWords(node, output, limits);

    return output;
};

// recursive function to find all words in the given node.
function findAllWords(node, arr, limits) {
    // base case, if node is at a word, push to output

    let queue = [node]
    let cnt = 0

    while (queue.length > 0) {
        let node = queue.shift()
        if (node.end) {
            arr.push([node.getWord(), node.value])
            cnt++
        }
        if (cnt == limits) {
            break
        }

        for (var child in node.children) {
            queue.push(node.children[child]);
        }
    }

    return;

    // if (node.end) {
    //     arr.unshift(node.getWord());
    // }

    // // iterate through each children, call recursive findAllWords
    // for (var child in node.children) {
    //     findAllWords(node.children[child], arr);
    // }
}

class Resolver {
    constructor(tags) {
        this.trie = new Trie()
        this.genTags(tags)
    }

    length() {
        return this.trie.length
    }

    isUnused(s) {
        for (let ss of ['length', 'gtoken', 'gid', 'size', 'publish']) {
            if (s == ss) {
                return true
            }
        }
        return false
    }

    genTags(tags) {
        let word_map = new Map()
        for (let [id, tag] of tags) {
            let [first, second] = parseTag(tag)
            if (this.isUnused(first)) {
                continue
            }
            // console.log(first, second)
            // console.log(word_map)
            let item = [id, genTag([first, second])]
            if (first) {
                let arr = []
                if (word_map.get(first)) {
                    arr = word_map.get(first)
                }
                arr.push(item)
                word_map.set(first, arr)
            }
            if (second) {
                let arr = []
                if (word_map.get(second)) {
                    arr = word_map.get(second)
                }
                arr.push(item)
                word_map.set(second, arr)
            }
            this.trie.insert(genTag([first, second]), [item])
        }
        for (let [word, ent] of word_map) {
            this.trie.insert(word, ent)
        }
        console.log('fin', Date.now())
    }

    find(s, limits = 20) {
        return this.trie.find(s, limits)
    }
}

export default Resolver
<template>
  <div>
    <book-carousel :books="randBooks" />
    <div class="text-h3 py-4"></div>
    <book-view :books="books" :pagination="pagination"
      @update="({ order, nextPage }) => getBooks(nextPage, this.pageSize, order)" />
  </div>
</template>

<script>
import BookView from "@/components/BookView";
import BookCarousel from "@/components/BookCarousel.vue";

export default {
  name: "Viewer",
  components: {
    BookView,
    BookCarousel,
  },
  data: () => ({
    drawer: null,
    books: [],
    randBooks: [],
    pageSize: 30,
    pagination: {
      page: 1,
      totalPage: 1,
      order: "new",
    },
  }),
  props: [],
  methods: {
    async getBooks(page, pageSize, order) {
      const data = await this.setLoading(this.$hanaWeb.bookList(
        page,
        pageSize,
        order
      ));
      this.books = data["item"];
      this.pagination = {
        page: data["page"],
        totalPage: data["totalpage"],
        order
      };
    },
    async getRandBooks() {
      const data = await this.setLoading(this.$hanaWeb.bookList(1, 5, "rand"));
      this.randBooks = data["item"];
    },
    async setLoading(promise) {
      this.$root?.$sysBar?.setLoading(true)
      try {
        return await promise;
      } finally {
        this.$root?.$sysBar?.setLoading(false)
      }
    },
  },
  created() {
    this.getBooks(1, this.pageSize, this.pagination.order);
    this.getRandBooks();
  },
};
</script>
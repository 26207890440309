<template>
  <v-dialog :value="value" scrollable fullscreen>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="onInput(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="title">Thumbnail</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="skipPage = pageIndex; showSkipPage = true;">
          <v-icon>{{ svgPath }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div id="front" style="height: 10px;"></div>
        <thumbnail :pages="pages" @click="onClick"/>
        <v-divider />
        <div class="pa-2" v-if="this.pageIndex + 1 < this.totalThumb">
          <v-list v-intersect="onIntersect">
            <v-list-item dense>
              <v-list-item-content>
                Loading...
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog max-width="300" v-model="showSkipPage">
      <v-card>
        <v-card-title>Thumbnail Page Switch </v-card-title>
        <v-card-text>
          <v-slider thumb-label :value="skipPage + 1" @input="(num) => skipPage = num - 1" min="1" :max="totalThumb" ticks="always">
            <template v-slot:append>
              <v-text-field :value="skipPage + 1" @input="(num) => skipPage = num - 1" class="mt-0 pt-0" type="number"
                style="width: 60px"></v-text-field>
            </template>
          </v-slider>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn plain class="pa-0" @click="
            loadPage(skipPage, true);
          showSkipPage = false;
          ">
            done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mdiArrowURightBottom } from '@mdi/js';
import Thumbnail from '@/components/Thumbnail.vue';

export default {
  components: { Thumbnail },
  props: ['value', 'getPage', 'total', 'totalThumb'],
  data: () => ({
    pageIndex: 0,
    pagesMap: {},
    pages: [],
    svgPath: mdiArrowURightBottom,
    showSkipPage: false,
    skipPage: 0
  }),
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
    onClick(item) {
      this.$emit("click", item);
    },
    onIntersect(entries) {
      console.log('intersect ', entries)
      for (let entry of entries) {
        if (entry.isIntersecting) {
          this.loadPage(this.pageIndex + 1)
        }
      }

    },
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    // async getPage(pageIndex) {
    //   let p = []
    //   const pp = []
    //   for (let i = 0; i < 30; ++i) {
    //     p.push({ img: pp[i], index: pageIndex * this.pageSize + i, merged: true })
    //   }
    //   return p
    // },
    async loadPage(pageIndex, reset = false) {
      console.log('pageIndex', pageIndex, reset)
      if (pageIndex >= this.totalThumb) {
        return;
      }
      if (this.pagesMap[pageIndex] == null) {
        await this.sleep(1000)
        const pages = await this.getPage(pageIndex)
        if (!pages) {
          return
        }
        this.pagesMap[pageIndex] = pages
      }
      this.pageIndex = pageIndex
      if (reset) {
        this.pages = [...this.pagesMap[pageIndex]]
        this.$vuetify.goTo('#front', {offset: 0})
      } else {
        this.pages.push(...this.pagesMap[pageIndex])
      }
    }
  },
  computed: {
  },
  mounted() {
    this.loadPage(0)
  }
}
</script>

<style></style>
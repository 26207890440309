// stolen from https://vue3js.cn/interview/JavaScript/debounce_throttle.html#%E4%BB%A3%E7%A0%81%E5%AE%9E%E7%8E%B0
function throttle(fn, waitMs) {
  // 利用闭包保存定时器和上次执行时间
  // 上次执行时间
  let timer = null,
    last;
  return function (...args) {
    const now = +new Date();
    if (last && now < last + waitMs) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, waitMs);
    } else {
      last = now;
      fn.apply(this, args);
    }
  };
}

function debounce(fn, waitMs, immediate = false) {
  let timer = null;

  // 返回一个函数，这个函数会在一个时间区间结束后的 wait 毫秒执行 func 函数
  return function (...args) {
    // 是否立即执行
    if (immediate) {
      fn.apply(this, args);
      return
    }

    // 函数被调用，清除定时器
    timer && clearTimeout(timer);
    console.log('waitMs', waitMs)

    // 当返回的函数被最后一个调用后（也就是用户停止了某个连续的操作）
    // 再过 wait 毫秒就执行 func
    // 这里直接使用箭头函数就不用保存执行上下文的引用了
    timer = setTimeout(() => {
      console.log('apply')
      fn.apply(this, args);
    }, waitMs);
  };
}

export {throttle, debounce}